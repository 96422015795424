@use '../../assets/styles/variables' as *;

.MuiPaper-root{
    border-radius: 25px !important;
  }

.hmy-modal-basic-header{
    display: flex;
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: $primary;
    .hmy-modal-basic-header-button{
        margin-right: 15px;
        color: $whiteColor;     
        font-size: 22px;
        &:hover{
          border: 2px solid $whiteColor;
        }
    }
}

.hmy-modal-basic-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}