@use '../../../assets/styles/variables' as *;

.section-france-matterport{
    height: 50vh;
    width: 100%;
    position: relative;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
        height: auto;
        flex-direction: column;
    }

    .matterport-france-container-image{
        height: 100%;
        @media only screen and (max-width: 768px) {
            height: auto;
            width: 100%;
        }

        img{
            height: 100%;
            @media only screen and (max-width: 768px) {
                width: 100%;
                height: auto;
            }
        }
    }

    .matterport-france-container-text{
        height: 100%;
        background-color: $primary;
        color: $whiteColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        @media only screen and (max-width: 768px) {
            width: 100%;
            height: auto;
            padding: 20px 0px;
            margin-top: -10px;
        }
    }

    .matterport-france-text{
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 0px 50px;
        gap: 40px;
        @media only screen and (max-width: 768px) {
            width: 100%;
            align-items: flex-start;
            gap: 20px;
        }
    }

    .btn-france-matterport-access{
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}