@use '../../../assets/styles/variables' as *;

.hmy-form-container{
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    gap: 15px;
    color: $primary;
    font-size: 22px;

    %hmy-form-button-base {
        background-color: $whiteColor;
        color: $primary;
        border-radius: 10px;
        padding: 12px 17px;
        font-size: 20px;
        cursor: pointer;
        box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
        @media only screen and (max-width: 768px) {
            padding: 10px;
            font-size: 18px;
        }
    }

    .hmy-form-button-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 20px;
        @media only screen and (max-width: 768px) {
            gap: 0px;
            justify-content: space-between;
        }
        .hmy-form-button-reset{
            @extend %hmy-form-button-base;
            margin-right: 30px;
            @media only screen and (max-width: 768px) {
                margin-right: 0px;
                background-color: $secondary;
                color: $whiteColor;
            }
            &:hover{
                background-color: $secondary;
                color: $whiteColor;
            }
        }
        .hmy-form-button-save{
            @extend %hmy-form-button-base;
            @media only screen and (max-width: 768px) {
                background-color: $greenColor;
                color: $whiteColor;
            }
            &:hover{
                background-color: $greenColor;
                color: $whiteColor;
            }
        }
        .hmy-form-button-save-disabled{
            @extend %hmy-form-button-base;
            cursor: default;
            background-color: $footerGrey;
            color: $whiteColor;
        }
        .hmy-form-button-cancel{
            @extend %hmy-form-button-base;
            @media only screen and (max-width: 768px) {
                background-color: $redColor;
                color: $whiteColor;
            }
            &:hover{
                background-color: $redColor;
                color: $whiteColor;
            }
        }
    
    }
}