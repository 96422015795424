@use '../../assets/styles/variables' as *;

.user-grouping-new-container{
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $menuBottomSize;
    }
}

.grouping-new-main {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    gap: 20px;
    flex-grow: 1;
    @media only screen and (max-width: 768px) {
        width: 95%;
        padding-bottom: 10px;
    }
}

.grouping-new-title{
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    cursor: pointer;
    .MuiSvgIcon-root{
        font-size: 50px !important;
    }      
    .grouping-new-title-text{
        font-size: 30px;
        font-weight: 600;
        color: $primary;
        @media only screen and (max-width: 768px) {
            font-size: 25px;
            margin-left: -5px;
        }
    }
}

.grouping-new-form-container{
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

.grouping-new-form-element-loaded{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}