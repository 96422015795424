@use '../../assets/styles/variables' as *;

.profile-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $smallNavbarSize;
    }

    .profile-main {
        height: 100%;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        gap: 15px;
        @media only screen and (max-width: 768px) {
            margin-top: 20px;
            width: 95%;
            padding-bottom: 50px;
        }
    }

    .profile-title{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        font-size: 42px;
        margin-bottom: 30px;
        @media only screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .profile-subtitle{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 26px;
        font-weight: 600;
        color: $primary;
        padding-left: 5px;
        margin-top: 10px;
    }

    .profile-title-text{
        font-weight: 600 !important;
        font-size: 36px !important;
        color: $primary !important;
        @media only screen and (max-width: 768px) {
            font-size: 30px !important;
        }
    }

    .profile-signOff-container{
        display: none;
        @media only screen and (max-width: 768px) {
            display: flex;
            width: 95%;
            margin-top: 20px;
        }
    }

    .profile-signOff-button{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 10px;
        background-color: $redColor;
        color: $whiteColor;
        cursor: pointer;
        box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
        font-size: 20px;
    }
}