.hmy-autocomplete-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.hmy-autocomplete-add-icon{
    font-size: 35px !important;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        font-size: 30px !important;
    }
}