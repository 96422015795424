@use '../../../assets/styles/variables' as *;

.grouping-card-container{
    border-radius: 15px;
    width: 95%;
    height: 400px;
    display: flex;
    flex-direction: column;
    transition: all .2s ease-in-out;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 75%;
        height: 270px;
    }
    &:hover{
        height: 405px;
        width: 97%;
    }
}

.grouping-card-primary{
    background-color: $secondary;
    color: $whiteColor;
    .grouping-card-users{
        display: flex;
        justify-content: center;
        padding: 30px 0px;
        @media only screen and (max-width: 768px) {
            padding: 20px 0px;
        }
        .grouping-card-users-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 50%;
            background-color: $primary;
            color: $whiteColor;
            cursor: pointer;
            @media only screen and (max-width: 768px) {
                padding: 10px;
            }
        }
    }

    .grouping-card-documents-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 38px;
        min-width: 38px;
        border-radius: 50%;
        background-color: $bgPrimary;
        color: $primary;
        font-size: 22px;
        cursor: pointer;
    }
}

.grouping-card-secondary{
    background-color: $whiteColor;
    color: $primary;
    .grouping-card-users{
        display: flex;
        justify-content: center;
        padding: 30px 0px;
        @media only screen and (max-width: 768px) {
            padding: 20px 0px;
        }
        .grouping-card-users-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 50%;
            background-color: $primary;
            color: $whiteColor;
            cursor: pointer;
            @media only screen and (max-width: 768px) {
                padding: 10px;
            }
        }
    }

    .grouping-card-documents-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 38px;
        min-width: 38px;
        border-radius: 50%;
        background-color: $bgPrimary;
        color: $primary;
        font-size: 22px;
        cursor: pointer;
    }
}

.grouping-card-tertiary{
    background-color: $primary;
    color: $whiteColor;
    .grouping-card-users{
        display: flex;
        justify-content: center;
        padding: 30px 0px;
        @media only screen and (max-width: 768px) {
            padding: 20px 0px;
        }
        .grouping-card-users-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-radius: 50%;
            background-color: $whiteColor;
            color: $primary;
            cursor: pointer;
            @media only screen and (max-width: 768px) {
                padding: 10px;
            }
        }
    }

    .grouping-card-documents-icon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 38px;
        min-width: 38px;
        border-radius: 50%;
        background-color: $bgPrimary;
        color: $primary;
        font-size: 22px;
        cursor: pointer;
    }
}

.grouping-card-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    cursor: pointer;
    width: 95%;
}

.grouping-card-text .MuiTypography-root{
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.grouping-card-delete{
    padding: 0px 10px 10px 10px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.grouping-card-delete-icon{
    border-radius: 50%;
    padding: 3px;
    background-color: $tertiary;
    color: $whiteColor;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 35px;
    @media only screen and (max-width: 768px) {
        font-size: 32px;
    }
}

.grouping-card-modal-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
}

.grouping-card-modal-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 10px 10px 10px !important;
}