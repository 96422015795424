@use '../../../assets/styles/variables' as *;

.section-welcome-container{
    height: 100vh;
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
    }
}

.section-welcome-image-container {
    width: 99%;
    display: flex;
    flex-direction: row;
    margin-top: $navbarSize;
    height: calc(100vh - $navbarSize);
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
        flex-direction: column;
        margin-top: 0px;
    }
}

.section-welcome-image{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    img{
        height: 90%;
        @media only screen and (max-width: 768px) {
            height: 300px;
        }
    }
    @media only screen and (max-width: 768px) {
        padding-right: 10px;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-end;
        order: 2;
    }
}

.section-welcome-greeting{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - $navbarSize);
    align-items: center;
    padding-right: 30px;
    min-width: 480px;
    flex-grow: 1;
    @media only screen and (max-width: 768px) {
        margin-top: calc($smallNavbarSize + 30px);
        padding-left: 20px;
        width: calc(100% - 20px);
        justify-content: flex-start;
        align-items: flex-start;
        order: 1;
    }

    .text-title{
        font-size: 75px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        margin-top: -20px;
        color: $primary;
        text-shadow: 0px 1px 1px $bgTertiary;
        @media only screen and (max-width: 768px) {
            font-size: 50px;
            margin-top: -10px;
            justify-content: flex-start;
        }
    }

    .text-body{
        font-size: 35px;
        display: flex;
        justify-content: center;
        color: $primary;
        animation: tracking-in-expand 1.2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        span {
            font-weight: bold;
            margin-left: 9px;
        }
        @media only screen and (max-width: 768px) {
            font-size: 26px;
            span {
                font-weight: bold;
                margin-left: 9px;
            }
        }
    }
}

.arrow-btn-transitioned{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 70px;
    @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        padding-left: 10%;
        padding-top: 50px;
    }
}

@keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }