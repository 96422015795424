@use '../../assets/styles/variables' as *;

.project-container{
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $menuBottomSize;
    }
}

.project-main {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    @media only screen and (max-width: 768px) {
        width: 95%;
        padding-bottom: 10px;
    }

    .project-title{
        width: 100%;
        display: flex;
        flex-direction: row; 
        align-items: center;
        color: $primary;
        cursor: pointer;
        .MuiSvgIcon-root{
            font-size: 50px !important;
        }      
        .project-title-text{
            font-size: 30px;
            font-weight: 600;
            color: $primary;
            @media only screen and (max-width: 768px) {
                font-size: 25px;
            }
        }
        .project-title-text-project{
            margin-left: 10px;
            font-size: 26px;
            font-weight: 500;
            @media only screen and (max-width: 768px) {
                font-size: 22px;
                margin-left: 0px;
            }
        }
    }

    .content-box-projects{
        width: 100%;
        margin-top: -30px;
        @media only screen and (max-width: 768px) {
            width: 90%;
        }
    }
}