@use '../../../../assets/styles/variables' as *;

.inbox-document-card-container{
    width: 28%;
    margin-right: 5%;
    @media only screen and (max-width: 768px) {
        width: 89%;
        margin-right: 0%;
    }
}

.inbox-document-card{
    width: 100%;
    background-color: $whiteColor;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    color: $primary;
    margin-top: 20px;
    font-size: 20px;
    &:hover{
        background-color: $primary;
        color: $whiteColor;
        box-shadow: 0px 8px 8px -8px rgba(36,36,36,1);
    }

    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }

    .inbox-document-card-row{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .date{
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .description{
        font-weight: 600;
        margin-bottom: 5px;
    }

    .extension{
        margin-top: 20px;
        cursor: pointer;
    }

    .icon-edit{
        cursor: pointer;
        font-size: 30px;
    }

    %icon-document-base {
        color: $whiteColor;
        border-radius: 50%;
        padding: 3px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
    }

    .icon-document{
        @extend %icon-document-base;
        background-color: $redColor;  
    }

    .icon-document-visible{
        @extend %icon-document-base;
        background-color: $greenColor;
    }

    .icon-document-text{
        margin-left: 5px;
    }
}

.inbox-document-card-edit{
    width: 100%;
    background-color: $primary;
    padding: 20px 20px 15px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    color: $whiteColor;
    margin-top: 20px;
    box-shadow: 0px 8px 8px -8px rgba(36,36,36,1);
}