@use '../../../assets/styles/variables' as *;

.section-lab{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: $bgPrimary;
    background-size: 100% 100%;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
        flex-direction: column;
    }

    %section-lab-base {
        width: 50%;
        height: 100%;
        text-align: center;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2{
            display: none;
            font-size: 80px;
            font-weight: 400;
            color: #fff;
        }
        .section-lab-laboratorio-text{
            font-size: 26px;
            font-weight: 300;
            color: #fff;
            margin-bottom: 30px;
            width: 60%;
            display: none;
            @media only screen and (max-width: 768px) {
                width: 95%;
            }
        }
        @media only screen and (max-width: 768px) {
            width: 100%;
            &:nth-child(1){
                padding-top: 20%;
            }
            h2{
                display: block;
                font-size: 40px;
                position: relative;
            }
            .section-lab-laboratorio-text{
                font-size: 22px;
                display: flex;
                position: relative;
                justify-content: center;
            }
            &::before{
                content:'';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,0.3);
            }
        }

        @media only screen and (min-width: 768px) {
            &:hover{
                cursor: pointer;
                &::before{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(0,0,0,0.6);
                }
                &:nth-child(1){
                    padding-top: 10%;
                }
                &:nth-child(2){
                    padding-top: 10%;
                }
                h2{
                    display: block;
                    position: relative;
                };
                .section-lab-laboratorio-text{
                    display: block;
                    position: relative;
                }
            }
        }
    }

    .section-lab-observatorio{
        @extend %section-lab-base;
        background-image: url('../../../assets/images/observatorio.png');
    }

    .section-lab-laboratorio{
        @extend %section-lab-base;
        background-image: url('../../../assets/images/materialab.png');
        @media only screen and (max-width: 768px) {
            display: none;
            .active{
                display: block;
            }        
        }
    }

    .section-lab-laboratorio-active{
        @extend %section-lab-base;
        background-image: url('../../../assets/images/materialab.png');
    }
}

.simple-section-lab{
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
    }
}