@use '../../assets/styles/variables' as *;

.landing-container {
    min-height:100vh;
    width: 100%;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (max-width: 768px) {
        min-height: calc(100% - $menuBottomSize);
    }
}

.landing-topbar-container {
    height: 120px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 5;
    @media only screen and (max-width: 768px) {
        height: 100px;
    }
}

.landing-topbar-container-logo{
    width: 50%;
    padding-left: 20px;
    img {
        width: 200px;
        @media only screen and (max-width: 768px) {
            width: 150px;
        }
    }
}

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.landing-topbar-container-button{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
	        animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;           
    @media only screen and (max-width: 768px) {
        display: none;
        animation: none;
    }
}

.landing-topbar-container-button-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 70px;
    background-color: $whiteColor;
    color: $primary;
    cursor: pointer;
    border-radius: 25px;
    font-size: 24px;
    transition: all .1s ease-in-out;
    &:hover{
        padding: 12px 72px;
        font-size: 26px;
    }
}

.landing-dialog{
    display: flex;
    flex-direction: column;
}

.landing-dialog-topbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
}

.landing-dialog-button-container{
    min-height: 390px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    background-color: $bgPrimary;
}

.landing-dialog-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 270px;
    background-color: $whiteColor;
    color: $primary;
    cursor: pointer;
    border-radius: 25px;
    font-size: 24px;
    transition: all .1s ease-in-out;
    box-shadow: 0px 2px 10px -8px rgba(36,36,36,1);
    &:hover{
        height: 60px;
        width: 275px;
        font-size: 26px;
    }
}

.landing-dialog .MuiPaper-root{
    background-color: $bgPrimary !important;
    border-radius: 15px !important;
}