.hmy-pdf-viewer-navBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1801;
    &.dissapear {
      display: none;
      transition: 1s;
    }
    & .MuiIconButton-root {
      color: white;
      &.Mui-disabled{
        color: #323639;
        transition: 0.5s;
      }
    }
    & .MuiTypography-root {
      color: white;
      font-weight: 900;
    }
}

.hmy-pdf-viewer-navBar-Pagination {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

.hmy-pdf-viewer-navBar-Zoom {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.hmy-pdf-viewer-navBar-Close {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}