@use '../../../assets/styles/variables' as *;

.section-euroshop-matterport{
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: $primary;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: calc(100vh - $menuBottomSize);
        align-items: flex-start;
    }

    .matterport-euroshop-container{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-image: url('../../../assets/images/euroshopGrande.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            background-image: none
        }
    }

    %matterport-euroshop-container-base{
        height: 100%;
        width: 60%;
        @media only screen and (max-width: 768px) {
            height: 50%;
            width: 100%;
        }
    }

    %matterport-euroshop-container-base2{
        @extend %matterport-euroshop-container-base;
        width: 40%;
        @media only screen and (max-width: 768px) {
            width: 100%;
            min-height: 50%;
        }
    }

    .matterport-euroshop-container-1{
        @extend %matterport-euroshop-container-base;
        background-image: none;
        @media only screen and (max-width: 768px) {
            background-image: url('../../../assets/images/euroshopMovil.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }      
    }

    .matterport-euroshop-container-2{
        @extend %matterport-euroshop-container-base2;
        display: flex;
        flex-direction: row;
        .matterport-euroshop-container-2-text{
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 20px;
            gap: 20px;
            @media only screen and (max-width: 768px) {
                width: 100%;
                gap: 10px;
            }
            .matterport-euroshop-container-2-text-1{
                line-height: 60px;
                color: #fff;
                span{
                    font-size: 80px;
                    font-weight: 400;                
                    b{
                        font-size: 90px;
                    }
                }
                @media only screen and (max-width: 768px) {
                    line-height: 40px;
                    margin: 0px !important;
                    span{
                        font-size: 32px;
                        font-weight: 400;                
                        b{
                            font-size: 37px;
                        }
                    }
                }

                .alignLeftYou{
                    padding-left: 8px;
                    @media only screen and (max-width: 768px) {
                        padding-left: 5px;
                    }
                }
            }

            .matterport-euroshop-container-2-text-2{
                line-height: 24px;
                color: #fff;
                max-width: 80%;
                font-size: 22px;
                @media only screen and (max-width: 768px) {
                    max-width: 90%;
                    font-size: 16px;
                    margin: 0px !important;
                }
            }

            .btn-euroshop-matterport-access{
                height: 70px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;
                @media only screen and (max-width: 768px) {
                    margin-top: 0px;
                    justify-content: center;
                }
            }
        }
        .matterport-euroshop-container-2-lines{
            width: 35%;
            background-image: url('../../../assets/images/rallasEuroshop.png');
            background-size: cover;
            background-repeat: no-repeat;
            @media only screen and (max-width: 768px) {
                display: none;
            }   
        }
    }
}