@use '../../../assets/styles/variables' as *;

.orders-table-row-element {
  color: $primary;
  display: flex;
  align-items: center;
  height: 60%;
  font-size: 0.8em;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }

  .MuiBadge-badge {
    font-size: 0.8em;
    background: #eb3232 !important;
    position: absolute;
    top: 6px;
    height: 17px;
    min-width: 14px;
    color: white;
  }
}

.orders-button {
  border: 1px solid $primary;
  border-radius: 4px;
  color: $primary;
  font-size: 14px;
  display: flex;
  column-gap: 8px;
  padding: 2px 4px;
  cursor: pointer;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    border: none;
    display: flex;
    justify-content: center;
    .green {
      color: green;
    }
    .red {
      color: red;
    }
  }

  .orders-button-icon {
    font-size: 14px;
    @media only screen and (max-width: 1024px) {
      font-size: 22px;
    }
  }
}
.orders-button.orders-button-view {
  border: 1px solid $secondary;
  color: $secondary;
  @media only screen and (max-width: 1024px) {
    font-size: 22px;
    border: none;
    display: flex;
    justify-content: center;
  }
}

.delete-icon {
  &:hover {
    cursor: pointer;
    color: $redColor;
  }
}

.error-icon {
  color: $redColor;
  font-size: 60px !important;
}

.isClosed {
  color: $footerGrey;
}

.isDeleted {
  color: $redColor;
}
