@use '../../../assets/styles/variables' as *;

.incidence-comment {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-block-end: 30px;

  .incidence-comment-head {
    display: flex;
    column-gap: 10px;
    align-items: baseline;
    font-size: 0.9em;
    padding-bottom: 10px;
    .incidence-comment-username {
      font-weight: bold;
    }
    .incidence-comment-date {
      font-size: 0.9em;
      color: #999;
    }
  }
}
