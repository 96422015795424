@use '../../assets/styles/variables' as *;

.hmy-input-file{
    padding: 14px;
    background-color: $tertiary;
    color: $whiteColor;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: relative;
    &:hover{
        box-shadow: 0px 8px 17px -8px rgba(36,36,36,1);
    }
    @media only screen and (max-width: 768px) {
        padding: 7px 10px;
    }
    input[type="file"] {
        display: none;
    }

    .custom-file-upload{
        font-size: 20px !important;
        @media only screen and (max-width: 768px) {
            font-size: 16px !important;
            font-weight: 500;
        }
    }
}