@use '../../../../assets/styles/variables' as *;

.card-user-container{
    width: 90%;
    background-color: $whiteColor;
    padding: 15px;
    border-radius: 25px;
    color: $primary;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    gap: 25px;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }

    .card-user-main{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-user-email{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            font-size: 20px;
            gap: 10px;
            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
        .card-user-status{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
        }
    }
}

.card-user-actions{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
}

.users-card-projects{
    border-radius: 25px;
    background-color: $secondary;
    color: $whiteColor;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 18px;
}

.users-card-delete{
    color: $redColor;
    background-color: $whiteColor;
}

.delete-icon{
    color: $redColor;
}

.card-user-isDeleted{
    color: $whiteColor;
    background-color: $redColor;
}