@use '../../../../../assets/styles/variables' as *;

.report-settings-users-list-form-container{
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .report-settings-users-list-form{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .report-settings-add-form{
        display: flex;
        flex-direction: column;
        width: 60%;
        gap: 10px;
        @media only screen and (max-width: 768px) {
            width: 90%;
        }
    }
    .report-settings-user-form{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .report-settings-user-form-email{
        font-weight: 600;
    }
    .report-settings-user-form-profile{
        margin-top: 5px;
    }
}

.report-settings-user-form-icon-delete{
    cursor: pointer;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redColor;
}

.report-settings-user-form-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

%report-settings-user-form-button-base{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid $primary;
    border-radius: 25px;
    padding: 5px 10px;
    font-weight: 500 !important;
    cursor: pointer;
}

.report-settings-user-form-button{
    @extend %report-settings-user-form-button-base;
    &:hover{
        color: $whiteColor;
        border: none;
        background-color: $secondary;
    }
}

.report-settings-user-form-button-cancel{
    @extend %report-settings-user-form-button-base;
    &:hover{
        color: $whiteColor;
        border: none;
        background-color: $redColor;
    }
}