@use '../../../../assets/styles/variables' as *;

.landmark-table{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 25px;
    font-size: 24px;
}

.landmark-table-header{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 65px !important;
    margin-top: 30px;
    background-color: $primary;
    align-items: center;
    border-radius: 15px;
    font-size: 20px;
    .landmark-table-header-element{
        color: $whiteColor;
        display: flex;
        align-items: center;
        height: 60%;
    }
    .separator{
        border-right: 2px solid $whiteColor;
    }

    .clickable{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
}

.landmark-table-icon{
    font-size: 25px !important;
}

.landmark-table-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 15px;
    overflow: auto;
    flex-grow: 1;
    font-size: 20px;
}