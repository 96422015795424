@use '../../assets/styles/variables' as *;

.groupings-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: $navbarSize;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $smallNavbarSize;
    }
}

.groupings-search{
    height: 70px;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
    }
}

.groupings-list{
    width: 85%;
    min-height: 410px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        min-height: 300px;
    }
}

.groupings-modal-text{
    font-size: 22px;
    text-align: center;
}