@use '../../../../assets/styles/variables' as *;

.landmark-card-container{
    width: calc(100% - 20px);
    background-color: $whiteColor;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
}

.landmark-card-file-description{
    padding-left: 5px;
    font-weight: 600;
    color: $primary;
}

.landmark-card-file-documentType-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.landmark-card-file-documentType{
    border-radius: 15px;
    padding: 7px 10px;
    margin: 5px 0px 5px 5px;
    color: $whiteColor;
    background-color: $primary;
}

.landmark-card-file-attachments{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    width: 100%;
}