@use '../../../../assets/styles/variables' as *;

.landmark-cards-container{
    width: 99%;
    background-color: $bgPrimary;
    margin-bottom: 20px;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.landmark-cards-date-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.landmark-cards-date{
    border-radius: 15px;
    border: 1px solid $primary;
    padding: 3px 5px;
    margin: 5px 0px 5px 5px;
    color: $primary;
    font-size: 24px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}