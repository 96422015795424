@use '../../assets/styles/variables' as *;

.hmy-inbox-file-zone {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hmy-inbox-file-zone-container {
  background-color: $whiteColor;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 30px 0px;
  color: $primary;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    background-color: inherit;
    padding: 0px;
  }
  &.active {
    background-color: $footerGrey;
  }

  .hmy-dropzone-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.hmy-inbox-container-dropzone-title {
  font-size: 24px !important;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.hmy-inbox-container-dropzone-text {
  font-size: 20px !important;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 18px !important;
  }
}

.hmy-inbox-container-dropzone-btn {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    height: 50px;
  }
}

.hmy-inbox-file-zone-info {
  font-size: 18px !important;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
