.hmy-pdf-viewer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

.hmy-pdf-viewer-topBar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1801;
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 0 10px;
    text-align: start;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

.hmy-pdf-viewer-pdfContainer {
    flex: 10 1 1px;
    background-color: #525659;
    &.desktop {
      overflow: hidden;
    }

    & .react-pdf__Document {
      height: 100%;
      display: flex;
      align-items: center;
      & .swiper-wrapper {
        & .swiper-slide {
          display: flex;
          justify-content: center;
        }
        &.block-swiper {
            scroll-snap-type: none;
            overflow: hidden;
        }
      }
      &.desktop {
        display: block;
        overflow: auto;
        & .react-pdf__Page {
          display: flex;
          min-height: 100%;
          min-width: fit-content;
          align-items: center;
          justify-content: center;
          & .react-pdf__Page__svg {
            margin: 20;
          }
        }
      }
    }
  }