@use '../../../assets/styles/variables' as *;

.hmy-icon-image{
    color: $whiteColor;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        width: 39px;
        height: 39px;
    }
}

.MuiTooltip-popper{
    background-color: $secondary !important;
    border-radius: 15px !important;
}

.MuiTooltip-tooltip{
    background-color: $secondary !important;
    border-radius: 15px !important;
}