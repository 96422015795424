$open: #5377ff;
$solved: #39a00d;
$cancelled: #f5222d;

.incidence-status-text {
  width: auto;
  display: flex;
  align-items: center;
  column-gap: 6px;
  & > .status-badge {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

.incidence-status-text.status-1 {
  color: $open;

  & > .status-badge {
    background-color: $open;
  }
}

.incidence-status-text.status-2 {
  color: $solved;

  & > .status-badge {
    background-color: $solved;
  }
}
.incidence-status-text.status-3 {
  color: $cancelled;

  & > .status-badge {
    background-color: $cancelled;
  }
}
