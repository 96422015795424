@use '../../../../assets/styles/variables' as *;

.no-landmarks-container {
    width: 100%;
    background-color: $whiteColor;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 30px;
    padding: 50px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.no-landmarks-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    .text-title{
        width: 90%;
        font-size: 28px;
        font-weight: 600;
        color: $secondary;
        text-align: center;
        @media only screen and (max-width: 768px) {
            width: 98%;
            font-size: 24px;
            font-weight: 600;
        }
    }
    .text-body{
        width: 70%;
        font-size: 20px;
        font-weight: 400;
        color: $blackColor;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        flex-wrap: wrap;
        @media only screen and (max-width: 768px) {
            width: 85%;
            font-size: 18px;
            text-align: center;
        }

        .text-body-navigate{
            font-weight: bold;
            cursor: pointer;
        }
        img{
            width: 30px;
        }
    }
}