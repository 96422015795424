@use '../../../assets/styles/variables' as *;

.hmy-single-form-element{
    width: calc(50% - 47px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    }   
}

.hmy-single-form-element-fullwidth{
    width: calc(100% - 40px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    } 
}

.hmy-single-form-element-multiline{
    width: calc(50% - 47px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    }   
}

.hmy-single-form-element-multiline-fullwidth{
    width: calc(100% - 40px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    } 
}

.hmy-single-form-element-title{
    font-size: 20px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    } 
}

.hmy-single-form-element-content{
    font-size: 18px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    } 
}

.hmy-single-form-no-edit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    min-height: 50.7px;
}

.hmy-single-form-no-edit-multiline{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    min-height: 50.7px;
}

.hmy-single-form-element-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    padding-top: 4px;
    @media only screen and (max-width: 768px) {
        padding-top: 2px;
    }
}

.hmy-single-form-element-text{
    font-size: 18px;
    font-weight: 500;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }
}

.hmy-single-form-no-edit-open-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

%hmy-single-form-button-base {
    background-color: $bgPrimary;
    color: $primary;
    border-radius: 10px;
    padding: 12px 17px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    @media only screen and (max-width: 768px) {
        padding: 10px;
        font-size: 16px;
    }
}

.hmy-single-form-element-buttons{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        gap: 0px;
        justify-content: space-between;
    }

    .hmy-single-form-button-save{
        @extend %hmy-single-form-button-base;
        @media only screen and (max-width: 768px) {
            background-color: $greenColor;
            color: $whiteColor;
        }
        &:hover{
            background-color: $greenColor;
            color: $whiteColor;
        }
    }

    .hmy-single-form-button-save-disabled{
        @extend %hmy-single-form-button-base;
        cursor: default;
        background-color: $footerGrey;
        color: $whiteColor;
    }

    .hmy-single-form-button-cancel{
        @extend %hmy-single-form-button-base;
        @media only screen and (max-width: 768px) {
            background-color: $redColor;
            color: $whiteColor;
        }
        &:hover{
            background-color: $redColor;
            color: $whiteColor;
        }
    }
}