@use '../../../assets/styles/variables' as *;

.hmy-form-element{
    width: calc(50% - 47px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    }   
}

.hmy-form-element-fullwidth{
    width: calc(100% - 40px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    } 
}

.hmy-form-element-multiline{
    width: calc(50% - 47px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    }   
}

.hmy-form-element-multiline-fullwidth{
    width: calc(100% - 40px);
    background-color: $whiteColor;
    color: $primary;
    border-radius: 10px;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px;
    } 
}

.hmy-form-element-title{
    font-size: 22px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.hmy-form-element-content{
    font-size: 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }
}