@use '../../assets/styles/variables' as *;

.hmy-selectable .MuiFormControl-root {
  border-radius: 50px;
  background-color: $whiteColor;
}

.hmy-selectable .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 50px;
  border-color: $primary;
}

.hmy-selectable .MuiOutlinedInput-root {
  border-radius: 50px !important;
}
