@use '../../../../../assets/styles/variables' as *;

.inbox-edit-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px !important;
    gap: 15px;
    .inbox-edit-form-inputs{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .MuiInput-root{
            color: $whiteColor;
            .MuiSvgIcon-root{
                color: $whiteColor;
            }
        }
        .MuiInput-underline:before {
            border-bottom-color: $whiteColor;
        }
    }

    @media only screen and (max-width: 768px) {
        font-size: 18px !important;
    }

    //Cambia el color del calendario del navegador a blanco
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    .inbox-edit-error-message{
        color: $redColor;
        font-size: 18px;
    }

    .inbox-edit-form-button-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .inbox-form-button{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .btn-edit-inbox-delete{
            background-color: $whiteColor;
            color: $primary;
            margin-left: 20px;
            border-radius: 10px;
            font-size: 20px;
            padding: 10px 15px;
            @media only screen and (max-width: 768px) {
                font-size: 18px;
                padding: 10px 10px;
            }
            &:hover{
                background-color: $redColor;
                color: $whiteColor;
                cursor: pointer;
            }
        }
        .btn-edit-inbox-accept{
            background-color: $whiteColor;
            color: $primary;
            border-radius: 10px;
            font-size: 20px;
            padding: 10px 15px;
            @media only screen and (max-width: 768px) {
                font-size: 18px;
                padding: 10px 10px;
            }
            &:hover{
                background-color: $greenColor;
                color: $whiteColor;
                cursor: pointer;
            }
        }
        .icon-delete-document{
            background-color: $redColor;
            color: $whiteColor;
            border-radius: 50%;
            padding: 3px;
            width: 35px;
            height: 35px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 32px;
        }
    }
}