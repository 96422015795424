@use '../../assets/styles/variables' as *;

.oAuth2-container{
    height: 100vh;
    width: 100%;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    .oAuth2-logo{
        width: 200px;
        animation-duration: 1.5s;
        animation-name: showhide;
        animation-iteration-count: infinite;
        @media only screen and (max-width: 768px) {
            width: 170px;
        }
    }
}

@keyframes showhide {
    from {
      opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }