@use '../../assets/styles/variables' as *;

%hmy-link-btn-base {
    padding: 12px 20px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    position: relative;
    &:hover{
        background-color: $primary;
        color: $whiteColor;
        box-shadow: 0px 6px 12px -8px rgba(36,36,36,1);
    }
    @media only screen and (max-width: 768px) {
        padding: 10px;
        font-size: 20px;
        font-weight: 500;
    }
}

.hmy-link-btn{
    @extend %hmy-link-btn-base;
    background-color: $whiteColor;
    color: $primary;
}

.hmy-link-btn-secondary{
    @extend %hmy-link-btn-base;
    background-color: $primary;
    color: $whiteColor;
}