@use '../../assets/styles/variables' as *;

.hmy-btn-help {
  height: 61.75px;
  width: 61.75px;
  background-color: $whiteColor;
  color: $primary;
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  &:hover {
    box-shadow: 0px 6px 12px -8px rgba(36, 36, 36, 1);
    background-color: $primary;
    color: $whiteColor;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 12px;
  }
}
.hmy-modal-help .MuiPaper-root.MuiPaper-elevation {
  position: absolute;
  right: 5%;
  top: 270px;
  @media only screen and (max-width: 768px) {
    width: 90vw;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.hmy-modal-help-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 35px;
  width: 400px;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    width: auto;
  }

  .hmy-modal-help-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    color: $primary;
  }
  .hmy-modal-help-text {
    font-size: 14px;
    padding-block: 20px;
  }
  .hmy-modal-help-comercial {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 20px;
  }
  a {
    color: $secondary;
    padding-block: 5px;
  }
}
