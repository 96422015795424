.incidence-file-add-container {
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .hmy-incidence-file-zone-selected {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 10px;
    font-size: 35px;
    width: 100%;
    margin-block-end: 20px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      font-size: 24px;
    }

    .hmy-incidence-file-zone-selected-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
