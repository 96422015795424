@use '../../../../../assets/styles/variables' as *;

.card-incidences-container {
  width: 90%;
  background-color: $whiteColor;
  padding: 15px;
  border-radius: 25px;
  color: $primary;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  gap: 15px;

  .card-incidences-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    position: relative;
  }
}
