@use '../../assets/styles/variables' as *;

%hmy-btn-base {
  height: 61.75px;
  background-color: $whiteColor;
  color: $primary;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  &:not(.disabled):hover {
    box-shadow: 0px 6px 12px -8px rgba(36, 36, 36, 1);
  }
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 12px;
  }
}

.hmy-btn {
  @extend %hmy-btn-base;
  &.filled {
    background-color: $primary;
    color: $whiteColor;
    &:not(.disabled):hover {
      background-color: $whiteColor;
      color: $primary;
    }
  }
  &:not(.disabled):hover {
    background-color: $primary;
    color: $whiteColor;
  }
}

.hmy-btn-secondary {
  @extend %hmy-btn-base;
  &.filled {
    background-color: $secondary;
    color: $whiteColor;
    &:not(.disabled):hover {
      background-color: $whiteColor;
      color: $secondary;
    }
  }
  &:not(.disabled):hover {
    background-color: $secondary;
    color: $whiteColor;
  }
}

.hmy-btn-errorpage {
  @extend %hmy-btn-base;
  &:not(.disabled):hover {
    background-color: $primary;
    color: $whiteColor;
  }
}
