@use '../../assets/styles/variables' as *;

.arrow-link{
    position: relative;
}

.arrow {
    position: absolute;
    transform: translate(-50%, -50%);
}

.arrow span {
    display: block;
    width: 50px;
    height: 50px;
    border-bottom: 15px solid $bgPrimary;
    border-right: 15px solid $bgPrimary;
    transform: rotate(45deg);
    margin: -20px;
    animation: scroll 2s infinite;
    @media only screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        border-bottom: 12px solid $bgPrimary;
        border-right: 12px solid $bgPrimary;
    }
}

.arrow span:nth-child(1){
    border-color: $secondary;
}

.arrow span:nth-child(2){
    border-color: $secondary;
}

.arrow span:nth-child(3){
    animation-delay: -.3s;
}

@keyframes scroll{
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-10px, -10px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(10px, 10px);
    }
}