@use '../../../../assets/styles/variables' as *;

.grouping-file-add-container {
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 40px;
  -webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }

  .hmy-inbox-file-zone-selected {
    width: 100%;
  }
}
@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

.grouping-file-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  .MuiSvgIcon-root {
    font-size: 50px !important;
  }
}

.grouping-file-new-title {
  font-weight: 600 !important;
  font-size: 36px !important;
  color: $primary !important;
  @media only screen and (max-width: 768px) {
    font-size: 30px !important;
  }
}
