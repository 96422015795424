@use '../../../assets/styles/variables' as *;

.projects-table-row-element {
  color: $primary;
  display: flex;
  align-items: center;
  height: 60%;
  .MuiBadge-badge {
    font-size: 0.8em;
    background: #eb3232 !important;
    position: absolute;
    top: 6px;
    height: 17px;
    min-width: 14px;
    color: white;
  }
}

.delete-icon {
  &:hover {
    cursor: pointer;
    color: $redColor;
  }
}

.error-icon {
  color: $redColor;
  font-size: 60px !important;
}

.isClosed {
  color: $footerGrey;
}

.isDeleted {
  color: $redColor;
}
