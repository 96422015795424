@use '../../../assets/styles/variables' as *;

.landing-images-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: $primary;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
        width: 100%;
        flex-direction: column;
    }

    .landing-image-container{
        width: 22%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 0;
        @media only screen and (max-width: 768px) {
            width: 90%;
        }
        img{
            width: 50%;
            @media only screen and (max-width: 768px) {
                width: 30%;
            }
        }
        p{
            width: 50%;
            color: $whiteColor;
            font-size: 22px;
            text-align: center;
            @media only screen and (max-width: 768px) {
                font-size: 18px;
                width: 90%;
            }
        }
    }
}