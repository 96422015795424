@use '../../assets/styles/variables' as *;

.home-container {
    width: 100%;
    background-color: #fff;
    .btn-up{
        position: fixed;
        bottom: 30px;
        right: 38px;
        background-color: $tertiary;
        z-index: 1;
        box-shadow: 0px 3px 10px -8px rgba(36,36,36,1);
        &:hover{
            background-color: $tertiary;
        }
        @media only screen and (max-width: 768px) {
            bottom: calc($menuBottomSize + 10px);
            right: 10px;
            width: 45px;
            height: 45px;
        }
        a{
            display: flex;
            align-items: center;
            color: $bgPrimary;
        }
    }
    
    .btn-up-hidden {
        display: none;
    }
}

.matterport-iframe-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 65px - 30px);
}