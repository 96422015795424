@use '../../../assets/styles/variables' as *;

.card-project-container{
    width: 90%;
    background-color: $whiteColor;
    padding: 15px;
    border-radius: 25px;
    color: $primary;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    gap: 15px;

    .card-project-number{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.card-project-users{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-project-isClosed{
    background-color: $footerGrey;
}

.delete-icon {
    &:hover{
        cursor: pointer;
        color: $redColor;
    }
}

.error-icon{
    color: $redColor;
    font-size: 60px !important;
}

.isDeleted{
    color: $redColor;
}