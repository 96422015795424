@use '../../../../assets/styles/variables' as *;

.hmy-table-row-landmark-container{
    width: 100%;
    background-color: $whiteColor;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    margin-bottom: 10px;
    font-size: 18px;
}

.hmy-table-row-landmark-date{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hmy-table-row-landmark-lines{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.hmy-table-row-landmark-workorder{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.hmy-table-row-landmark-workorder-line{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.hmy-table-row-landmark-workorder-desc{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.hmy-table-row-landmark-files{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 7px;
}

.hmy-table-row-landmark-file{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
}

.hmy-table-row-landmark-file-docType{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hmy-table-row-landmark-file-attachments{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.hmy-table-row-landmark-file-attachments-centered{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
}

.hmy-table-row-landmark-file-visibility{
    display: flex;
    flex-direction: row;
    justify-content: center;
}