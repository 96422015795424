@use '../../assets/styles/variables' as *;

.cookies-container {
    position: fixed;
    z-index: 2;
    bottom: 0;
    background-color: white;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        padding: 20px 0px 100px 0px;
    }
}

.hmy-logo-container {
    width: 18%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    img{
        width: 220px;
        height: auto;
        @media only screen and (max-width: 768px) {
            width: 170px;
        }
    }
}

.message-container{
    width: 60%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
        width: 90%;
    }
}

.message-title{
    font-size: 18px;
    font-family: "Raleway";
    color: black;
    font-weight: bold;
    margin: 5px 0px;
}

.message-text{
    font-size: 14px;
    font-family: "Raleway";
    color: black;
    margin: 5px 0px;
    @media only screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.button-container{
    width: 20%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 10px;
    }
}

.button-cookies{
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    background-color: $primary;
    color: $whiteColor;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.button-cookies-cancel{
    padding: 10px 20px;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    margin-top: 15px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}