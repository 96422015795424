@use './variables' as *;

@font-face {
  font-family: 'Roobert';
  src: url(./fonts/Roobert-Regular.ttf) format('truetype'),
  url(./fonts/Roobert-Regular.woff) format('woff'),
  url(./fonts/Roobert-Regular.woff2) format('woff2');
}

html, body {
  margin:0px !important;
  min-height: 100vh;
  width: 100vw;
  padding: 0 !important;
  overflow-x: hidden;
  font-family: 'Roobert';
  font-size: 22px;
  scroll-behavior: smooth;
  @media only screen and (min-width: 768px){
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
    /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: $footerGrey $footerBackColor;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: $footerBackColor;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $footerGrey;
    border-radius: 20px;
    border: 3px solid $footerBackColor;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

a:link, a:visited, a:active{
  text-decoration: none;
  color: inherit;
} 