@use '../../assets/styles/variables' as *;

.login-container {
    min-height:100vh;
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        min-height: calc(100% - $menuBottomSize);
        padding-bottom: 50px;
    }
}

.login-topbar-container {
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
        height: 65px;
        width: 100%;
    }
}

.login-topbar-container-logo{
    width: 50%;
    padding-left: 20px;
    padding-top: 10px;
    img {
        width: 200px;
        @media only screen and (max-width: 768px) {
            width: 150px;
        }
    }
}

.login-container-content {
    height: calc(100vh - 150px);
    width: 100%;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - 130px);
        width: 100%;
        margin-top: 30px;
    }
}