@use '../../assets/styles/variables' as *;

.modal-container .MuiPaper-root{
  border-radius: 25px !important;
}

%modal-buttons-base {
    background-color: $secondary;
    color: $whiteColor;
    padding: 12px 24px;
    font-size: 20px;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
}

.modal-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px;
  .modal-text{
      text-align: center;
  }
  .modal-buttons{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
  }

  .modal-buttons-accept{
    @extend %modal-buttons-base;  
      &:hover{
          background-color: $redColor;
      }
  }
  .modal-buttons-cancel{
    @extend %modal-buttons-base;  
      &:hover{
          background-color: $greenColor;
      }
  }
}