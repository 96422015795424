@use '../../../../assets/styles/variables' as *;

.users-table-row-element{
    color: $primary;
    display: flex;
    align-items: center;
    height: 60%;
}

.users-table-projects{
    min-width: 50px;
    border-radius: 25px;
    background-color: $secondary;
    color: $whiteColor;
    padding: 5px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.users-table-delete{
    background-color: $redColor;
}

.delete-icon {
    &:hover{
        cursor: pointer;
        color: $redColor;
    }
}

.resend-icon{
    color: $secondary;
    cursor: pointer;
}

.check-icon{
    color: $greenColor;
}

.isDeleted{
    color: $redColor;
}