@use '../../../../assets/styles/variables' as *;

.login-form-content {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 70px;
    animation: fade-in 1.2s linear both;
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.login-form-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.login-inputEmail .MuiInputBase-input{
    font-size: 20px !important;
}

.login-inputEmail .MuiFormLabel-root{
    font-size: 20px !important;
}

.login-inputPassword .MuiInputBase-input{
    font-size: 20px !important;
}

.login-inputPassword .MuiFormLabel-root{
    font-size: 20px !important;
}

.login-inputPassword .MuiButtonBase-root{
    font-size: 40px !important;
    @media only screen and (max-width: 768px) {
        font-size: 30px !important;
    }
}

.login-form-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

%login-form-send-base{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 70px;
    border-radius: 25px;
    font-size: 24px;
    transition: all .1s ease-in-out;
    color: $primary;
}
    

.login-form-send{
    @extend %login-form-send-base;
    background-color: $whiteColor;
    cursor: pointer;
    &:hover{
        padding: 12px 72px;
        font-size: 26px;
    }
}

.login-form-send-disabled {
    @extend %login-form-send-base;
    background-color: $footerGrey;
}

.login-form-goback{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 80%;
}