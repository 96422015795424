.user-grouping-edit-container{
    display: flex;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 40px;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
    }
}

.user-grouping-edit-content-item-loaded{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 35px;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
    }
}