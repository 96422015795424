@use '../../../assets/styles/variables' as *;

.card-import-project-container{
    width: 90%;
    background-color: $whiteColor;
    padding: 15px;
    border-radius: 25px;
    color: $primary;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    gap: 15px;
    .card-import-project-number{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}