@use '../../../assets/styles/variables' as *;

@keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-88%);
      -webkit-transform: translateX(-88%);
      transform: translateX(-88%);
    }
}

.landing-label-effect-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    position: relative;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
        width: 100%;
    }

    .landing-label-effect{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-grow: 1;
        z-index: 0;
        position: relative;
        align-items: center;
        background-color: $bgPrimary;
        border-bottom: none;
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 85%);
        @media only screen and (max-width: 768px) {
            clip-path: polygon(0 0, 100% 0, 100% 80%, 0 85%);
        }

        p {
            font-size: 28px;
            color: $blackColor;
            margin-top: 15%;
            width: 50%;
            text-align: center;
            @media only screen and (max-width: 768px) {
                margin-top: 40%;
                width: calc(100% - 10px);
                font-size: 22px;
                padding: 0px 5px;
            }
        }
    }

    .landing-label-effect-next{
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        color: $whiteColor;
        @media only screen and (max-width: 768px) {
            height: 70px;
        }
        &:hover{
            font-size: 60px;
        }
    }

    .landing-label-effect-moving-container{
        position: absolute;
        height: 150px;
        width: 110%;
        bottom: 20%;
        z-index: 1;
        background-color: $secondary;   
        display: flex;
        flex-direction: row;
        justify-content: center;
        transform: rotate(-0.013turn);
        overflow: hidden;
        @media only screen and (max-width: 768px) {
            height: 100px;
        }  
        .landing-label-effect-moving{
            width: 110%;
            display: flex;
            flex-direction: row;
            align-items: center;        
            animation: my-animation 20s linear infinite;
            @media only screen and (max-width: 768px) {
                width: 180%;
                animation: my-animation 15s linear infinite;
            }       
            p {
                color: $whiteColor;
                font-size: 50px;
                font-weight: bold;
                margin-right: 40px;
                display: flex;
                justify-content: center;
                text-align: center;
                @media only screen and (max-width: 768px) {
                    font-size: 30px;
                }
                
            }
            .landing-label-effect-moving-bullet{
                font-size: 100px;             
                padding-bottom: 5px;
                @media only screen and (max-width: 768px) {
                    font-size: 80px;
                }
            }
        }
    }
}