@use '../../../assets/styles/variables' as *;

%hmy-icon-visibility-base {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $whiteColor;
    @media only screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
}

.hmy-icon-visibility-on{
    @extend %hmy-icon-visibility-base;
    background-color: $greenColor;
}
.hmy-icon-visibility-off{
    @extend %hmy-icon-visibility-base;
    background-color: $redColor;
}