@use '../../assets/styles/variables' as *;

.language-container{
    width: 90px;
}

.btn-language{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    .flag-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
}

.span-language{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    .flag-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
}

.icon-font-size{
    color: $primary;
    font-size: 28px;
}