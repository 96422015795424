.drawing-container{
    width: 100%;
    padding-bottom: 30px;
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
        margin-top: 60px;
    }
}

.drawing-list-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

.drawing-iframe-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 65px - 30px);
}

.drawing-iframe{
    border: none;
    width: 100%;
    height: 100%;
}