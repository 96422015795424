@use '../../assets/styles/variables' as *;

.projects-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $smallNavbarSize;
        justify-content: flex-start;
    }
    .project-btn-up{
        display: none;
        position: fixed;
        @media only screen and (max-width: 768px) {
            bottom: calc($menuBottomSize + 10px);
            right: 10px;
            width: 45px;
            height: 45px;
            background-color: $tertiary;
            z-index: 1;
            display: flex;
        }
        a{
            display: flex;
            align-items: center;
            color: $bgPrimary;
        }
    }
}

.projects-main {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    @media only screen and (max-width: 768px) {
        margin-top: 70px;
        width: 90%;
        margin-top: 30px;
        padding-bottom: 10px;
    }
}

.projects-search{
    height: 60px;
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        gap: 20px;
    }
}

.projects-switch-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    width: 97%;
    @media only screen and (max-width: 768px) {
        justify-content: flex-start;
        margin-top: 20px;
        padding-left: 10px;
        width: calc(97% - 10px);
    }
    .switch-show-closed{
        margin-left: 20px;
    }

    .MuiTypography-root{
        font-size: 18px;
        font-weight: 400;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
            font-weight: 300;
        }
    }
}