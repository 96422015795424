@use '../../assets/styles/variables' as *;
.hmy-selectable-autocomplete-field input {
  border: none !important;
}
.hmy-selectable-autocomplete .MuiFormControl-root {
  border-radius: 50px;
  background-color: $whiteColor;
}

.hmy-selectable-autocomplete .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 50px;
  border-color: $primary;
}

.hmy-selectable-autocomplete .MuiOutlinedInput-root {
  border-radius: 50px !important;
}
.hmy-selectable-autocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0;
}
