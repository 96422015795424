@use '../../../assets/styles/variables' as *;

.documents-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.landmarks-seeHidden{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    @media only screen and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .landmarks-seeHidden-text{
        font-size: 18px;
        margin-top: -5px;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
            margin-top: -3px;
        }
    }
}

%landmarks-buttons-absolute-base {
    display: none !important;
    position: fixed !important;
    @media only screen and (max-width: 768px) {
        bottom: calc($menuBottomSize + 10px);
        right: 12px;
        background-color: transparent;
        z-index: 1;
        display: flex !important;
    }
}

%landmarks-buttons-icons-base{
    width: 45px;
    height: 45px;
}

.landmarks-buttons-absolute{
    @extend %landmarks-buttons-absolute-base;
    @extend %landmarks-buttons-icons-base;
    width: 45px !important;
    height: 45px !important;
}

.landmarks-buttons-icons{
    @extend %landmarks-buttons-icons-base;
    width: 45px !important;
    height: 45px !important;
}

.landmarks-buttons-absolute-container{
    @extend %landmarks-buttons-absolute-base; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.project-modal-document-types{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 20px !important;
}