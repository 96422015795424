@use '../../../assets/styles/variables' as *;

.section-matterport{
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: $bgPrimary;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: calc(100vh - $menuBottomSize);
        align-items: flex-start;
    }

    .matterport-container{
        width: calc(100% - 7%);
        height: 100%;
        color: $blackColor;
        display: flex;
        flex-direction: row;
        padding-left: 7%;
        align-items: center;
        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            padding-left: 0%;
            padding: 10px 0px;
            height: calc(100% - 20px);
        }
    }
    
    .matterport-text-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: $primary;
        width: 31%;
        height: 100%;
        gap: 40px;
        @media only screen and (max-width: 768px) {
            width: calc(100% - 20px);
            height: auto;
            padding: 10px;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
        }
        .matterport-text-title{
            font-weight: 500;
            font-size: 60px;
            @media only screen and (max-width: 768px) {
                font-weight: 600;
                font-size: 27px;
            }
        }
    }

    .matterport-video-container {
        width: 31%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: relative;
        @media only screen and (max-width: 768px) {
            width: calc(50% - 20px);
            height: auto;
            padding: 0px 10px;
        }

        .matterport-movil-video{
            width: 310px;
            height: 580px;
            border-radius: 50px;
            @media only screen and (max-width: 768px) {
                width: 150px;
                height: 240px;
                border-radius: 20px;
            }
        }
        
        .matterport-movil-image{
            position: absolute;
            width: 360px;
            height: 580px;
            @media only screen and (max-width: 768px) {
                height: 250px;
                width: 160px;
            }
        }
    }

    .matterport-notext-container{
        display: flex;
        flex-direction: column;
        width: 31%;
        height: 100%;
        justify-content: center;
        align-items: flex-end;
        flex-grow: 1;
        @media only screen and (max-width: 768px) {
            width: 50%;
            height: 240px;
        }
    }

    .matterport-image-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:30px;
        @media only screen and (max-width: 768px) {
            width: 100%;
            justify-content: flex-end;
        }
    }

    .matterport-image-main{
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        img{
            width: 90%;
            @media only screen and (max-width: 768px) {
                padding-right: 0px;
            }
        }
    }

    .matterport-image-main2{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .matterport-book{
            width: 90%;
            @media only screen and (max-width: 768px) {
                margin-left: 40px;
            }
        }
    }
}

.btn-matterport-access{
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.btn-matterport-access-small{
    display: none;
    @media only screen and (max-width: 768px) {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
}