@use '../../../assets/styles/variables' as *;

.users-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: 25px;
    }
    .users-btn-up{
        display: none;
        position: fixed;
        @media only screen and (max-width: 768px) {
            bottom: calc($menuBottomSize + 10px);
            right: 10px;
            width: 45px;
            height: 45px;
            background-color: $tertiary;
            z-index: 1;
            display: flex;
        }
        a{
            display: flex;
            align-items: center;
            color: $bgPrimary;
        }
    }
}

.users-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    @media only screen and (max-width: 768px) {
        margin-top: 50px;
        width: 95%;
        padding-bottom: 10px;
    }
}

.users-modal-text{
    font-size: 22px;
}

.users-search{
    height: 70px;
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
    }
}

.users-switch-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    width: 97%;
    gap: 5px;
    @media only screen and (max-width: 768px) {
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 15px;
        flex-wrap: wrap;
        width: 100%;
        gap: 0px;
    }
    .MuiTypography-root{
        font-size: 18px;
        font-weight: 400;
        @media only screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 300;
        }
    }
}