@use '../../../../assets/styles/variables' as *;

.hmy-user-edit-form-element-content-item-loaded{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hmy-user-edit-form-observatoryaccess-container{
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        margin-top: 10px;
        width: 100%;
    }
}

.hmy-user-edit-form-observatoryaccess-button{
    border-radius: 10px;
    padding: 15px 20px;
    background-color: $whiteColor;
    color: $primary;
    cursor: pointer;
    box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
    font-size: 18px;
    @media only screen and (max-width: 768px) {
        padding: 10px;
        background-color: $greenColor;
        color: $whiteColor;
        font-size: 16px;
    }
    &:hover{
        background-color: $greenColor;
        color: $whiteColor;
    }
}