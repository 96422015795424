@use '../../assets/styles/variables' as *;

.static-password-container {
    min-height: 100vh;
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    .static-password-main {
        height: 100%;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        gap: 30px;
        @media only screen and (max-width: 768px) {
            width: 95%;
            padding-bottom: 50px;
            margin-top: 15px;
            gap: 15px;
        }
    }

    .static-password-logo-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        padding-left: 30px;
    }

    .static-password-logo {
        width: 270px;
        @media only screen and (max-width: 768px) {
            width: 170px;
        }
    }

    .static-password-text{
        width: 80%;
        font-size: 44px;
        text-align: center;
        color: $primary;
        @media only screen and (max-width: 768px) {
            width: 100%;
            font-size: 24px;
        }
    }

    .static-password-content{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media only screen and (max-width: 768px) {
            width: 100%;
            gap: 30px;
        }
    }

    .static-password-button-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    %static-password-button-base {
        background-color: $whiteColor;
        color: $primary;
        border-radius: 10px;
        padding: 15px 20px;
        cursor: pointer;
        box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
        font-size: 24px;
        @media only screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    .static-password-button{
        @extend %static-password-button-base;
        @media only screen and (max-width: 768px) {
            background-color: $primary;
            color: $whiteColor;
        }
        &:hover{
            background-color: $primary;
            color: $whiteColor;
        }
    }

    .static-password-button-disabled{
        @extend %static-password-button-base;
        cursor: none;
        background-color: $footerGrey;
        color: $whiteColor;
    }
}