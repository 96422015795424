@use '../../../assets/styles/variables' as *;

.user-new-container{
    display: flex;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 40px;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
    }
}

.user-new-title{
    font-weight: 600 !important;
    font-size: 36px !important;
    color: $primary !important;
    @media only screen and (max-width: 768px) {
        font-size: 30px !important;
    }
}