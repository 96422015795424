@use '../../../assets/styles/variables' as *;

.management-navigation{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    @media only screen and (max-width: 768px) {
        padding: 0px 0px 0px 20px;
    }
    .management-navigation-element{
        width: 220px;
        height: 220px;
        position: relative;
        display: flex;
        border-radius: 10px;
        background-image: url('../../../assets/images/bocadillo_navy.png');
        background-size: 100%;
        justify-content: center;
        cursor: pointer;
        transition: all .2s ease-in-out;
        font-weight: 500;
        font-size: 18px;
        color: $primary;
        @media only screen and (max-width: 768px) {
            width: 85px;
            height: 85px;
        }
        .management-navigation-element-icon{
            position: absolute;
            top: 25px;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media only screen and (max-width: 768px) {
                top: 10px;
            }
            img{
                width: 40%;
                @media only screen and (max-width: 768px) {
                    width: 50%;
                }
            }
        }

        &:hover{
            width: 240px;
            height: 240px;
            font-weight: 600;
            font-size: 22px;
            @media only screen and (max-width: 768px) {
                width: 90px;
                height: 90px;
                font-size: 20px;
            }
        }

        &.active{
            width: 240px;
            height: 240px;
            background-image: url('../../../assets/images/bocadillo_energy.png');
            font-weight: 600;
            font-size: 22px;
            @media only screen and (max-width: 768px) {
                width: 90px;
                height: 90px;
                font-size: 20px;
            }
            .management-navigation-element-icon{
                top: 30px;
                @media only screen and (max-width: 768px) {
                    top: 12px;
                }
            }
        }
    }
}