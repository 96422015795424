@use '../../assets/styles/variables' as *;

.file-list-ul{
    width: 100%;
    list-style: none;
    padding: 0px;
    margin: 0px;
    @media only screen and (max-width: 768px) {
        padding: 20px 0px;
    }
}

.file-list-li{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.file-list-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.file-list-accept{
    background-color: $greenColor;
    color: $whiteColor;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    &:hover{
        padding: 7px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.file-list-cancel{
    background-color: $redColor;
    color: $whiteColor;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    &:hover{
        padding: 7px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 18px;
    }
}