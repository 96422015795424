.infinite-scroll-component__outerdiv{
    width: 100%;
    .hmy-infinite-scroll{
        display: none;
        @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: 10px;
            align-items: center;
            gap: 20px;
            width: 100%;
        }
    }
}