@use '../../assets/styles/variables' as *;

.order-container {
  min-height: calc(100vh - $navbarSize);
  width: 100%;
  background-color: $bgPrimary;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: $navbarSize;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
    margin-top: $smallNavbarSize;
    justify-content: flex-start;
  }
  .order-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primary;
    cursor: pointer;
    .MuiSvgIcon-root {
      font-size: 50px !important;
    }
    .order-title-text {
      font-size: 30px;
      font-weight: 600;
      color: $primary;
      @media only screen and (max-width: 768px) {
        font-size: 25px;
      }
    }
    .order-title-text-order {
      margin-left: 10px;
      font-size: 26px;
      font-weight: 500;
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        margin-left: 0px;
      }
    }
  }
}

.order-title-add-project {
  display: flex;
  column-gap: 20px;
  align-self: flex-start;
  width: 60%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    row-gap: 10px;
  }
}
.order-main {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 90%;
    padding-bottom: 10px;
  }
}

.order-title-text {
  text-transform: uppercase;
  text-align: left;
  align-self: flex-start;
  padding-block: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 1em !important;
  }
}

.order-header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  justify-content: space-between;
  background-color: $whiteColor;
  border-radius: 16px;
  padding: 15px 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  & > .order-header-inline {
    flex-direction: row;
    align-items: start;
    column-gap: 10px;
  }
  .order-header-phone {
    display: flex;
    align-items: center;
    column-gap: 6px;

    & > input {
      border: none;
    }

    & > span {
      cursor: pointer;
    }
  }
  .MuiFormControlLabel-label {
    font-size: 18px;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 10px;
    font-size: 0.7em !important;
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 5px;
    }
  }
}

.order-totals {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  & > .order-totals-row {
    margin-block-end: 15px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    & .order-totals-row-line {
      background-color: #333;
      height: 1px;
      margin: 0 20px;
    }
  }

  & > .order-totals-total {
    justify-content: flex-end;
    align-items: center;
    font-size: 24px;
    color: $primary;
    display: flex;
    column-gap: 6px;
    @media only screen and (max-width: 768px) {
      font-size: 1em !important;
    }
  }
  & > .order-totals-total-vat {
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: $primary;
    display: flex;
    column-gap: 6px;
    @media only screen and (max-width: 768px) {
      font-size: 0.9em !important;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.8em !important;
  }
}

.urgent-btn {
  background-color: $primary;
  padding: 8px 16px 6px;
  color: white;
  cursor: pointer;
  border-radius: 15px;
}
