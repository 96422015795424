@use '../../assets/styles/variables' as *;

.hmy-input-password-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 40px;
    @media only screen and (max-width: 768px) {
        gap: 20px;
    }

    .inputPassword .MuiInputBase-input{
        font-size: 20px !important;
    }

    .inputPassword .MuiFormLabel-root{
        font-size: 20px !important;
    }

    .inputPassword .MuiButtonBase-root{
        font-size: 35px !important;
        @media only screen and (max-width: 768px) {
            font-size: 30px !important;
        }
    }
}

.validator-password-container {    
    width: calc(100% - 40px);    
    background-color: $whiteColor;
    border-radius: 10px;
    box-shadow: 0px 6px 12px -8px rgba(36,36,36,1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

%validator-password-text-base {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-size: 16px !important;
    }
}

.validator-password-text{
    @extend %validator-password-text-base;
    color: $greenColor;
}

.validator-password-text-error{
    @extend %validator-password-text-base;
    color: $redColor;
}

.validator-password-text-safe{
    @extend %validator-password-text-base;
    color: #ffa623;
}

.validator-password-text-black{
    color: #000;
    font-weight: bold;
}

.validator-password-icon-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 35px !important;
    @media only screen and (max-width: 768px) {
        font-size: 30px !important;
    }
}