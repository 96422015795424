@use '../../assets/styles/variables' as *;

.MuiPaper-root{
    border-radius: 25px !important;
  }

.hmy-modal-file-header{
    display: flex;
    width: 100%;
    height: 65px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $primary;
    font-size: 24px;
    .hmy-modal-file-header-text{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $whiteColor;
        padding-left: 20px;
        flex-grow: 1;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
            justify-content: flex-start;
            width: 100%;
        }
    }
    .hmy-modal-file-header-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        color: $whiteColor;
        padding-right: 20px;
        @media only screen and (max-width: 768px) {
            justify-content: flex-end;
            gap: 15px;
            padding-right: 10px;
        }    
    }

    .hmy-modal-file-header-button{
        font-size: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &:hover{
            border: 2px solid $whiteColor;
        }
    }
}

.hmy-modal-file-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  height: calc(100vh - 65px - 30px);
}