.hmy-simple-selectable-option-default{
    font-weight: 500 !important;
    font-size: 20px !important;
    margin-right: 10px !important;
    @media only screen and (max-width: 768px) {
        font-size: 16px !important;
        padding-top: 5px !important;
    }
} 

.hmy-simple-selectable-option{
    font-weight: 500 !important;
    font-size: 20px !important;
    @media only screen and (max-width: 768px) {
        font-size: 16px !important;
    }
}