@use '../../assets/styles/variables' as *;

.import-projects-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $menuBottomSize;
    }

    .import-project-btn-up{
        display: none;
        position: fixed;
        @media only screen and (max-width: 768px) {
            bottom: calc($menuBottomSize + 10px);
            right: 10px;
            width: 50px;
            height: 50px;
            background-color: $tertiary;
            z-index: 1;
            display: flex;
        }
        a{
            display: flex;
            align-items: center;
            color: $bgPrimary;
        }
    }
}

.import-projects-main {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
        width: 95%;
        padding-bottom: 10px;
    }

    .import-projects-title{
        width: 100%;
        display: flex;
        flex-direction: row; 
        align-items: center;
        cursor: pointer;
        .MuiSvgIcon-root{
            font-size: 50px !important;
        }      
        .import-title-text{
            font-size: 35px;
            font-weight: 500;
            color: $primary;
            @media only screen and (max-width: 768px) {
                font-size: 25px;
            }
        }
    }
     
    .import-projects-search{
        height: 70px;
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        @media only screen and (max-width: 768px) {
            margin-top: 40px;
            flex-direction: column-reverse;
            gap: 15px;
        }
    }

    .infinite-scroll-header{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background-color: $primary;
        color: $whiteColor;
        margin-top: 10px;
    }
}