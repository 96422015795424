@use '../../assets/styles/variables' as *;

.horizontal-scroll-component-container {
    width: 100%;
    min-height: 410px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: 300px !important;
    }

    .swiper {
        height: 100%;
        width: 100%;
        flex-grow: 1;
        flex-basis: 1px;

        .swiper-wrapper {
            height: 100%;
            width: 100%;

            .horizontal-scroll-slide-container.swiper-slide {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }

        .swiper-button-prev{
            margin-left: -10px;
            color: $primary;
        }

        .swiper-button-next{
            margin-right: -10px;
            color: $primary;
        }
    }
}