@use '../../../../../assets/styles/variables' as *;

.report-settings-users-list-form-element{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .report-settings-user-form{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .report-settings-user-form-email{
        font-weight: 600;
        font-size: 22px;
        @media only screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
    .report-settings-user-form-profile{
        margin-top: 5px;
    }

    .report-settings-user-form-icon-delete{
        cursor: pointer;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $redColor;
    }
}