@use '../../assets/styles/variables' as *;

.report-container{
    min-height: calc(100vh - $navbarSize - 100px);
    width: 100%;
    background-color: $bgPrimary;
    padding-top: calc($navbarSize + 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media only screen and (max-width: 768px) {
        padding-top: 100px;
        padding-bottom: 30px;
    }
}

.report-list-container{
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

.report-iframe-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 65px - 30px);
}

.report-iframe{
    border: none;
    width: 100%;
    height: 100%;
}