@use '../../assets/styles/variables' as *;

.load-component-progress{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .load-component-progress-bar{
        width: 90%;
    }
    .load-component-progress-text{
        color: $whiteColor;
    }
}