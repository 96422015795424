.user-grouping-form-element-loaded {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hmy-form-section-vertical {
  display: grid;
  grid-template-columns: 35% 65%;

  .hmy-form-section-vertical-generic {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .hmy-form-element,
    .hmy-form-element-multiline {
      width: calc(100% - 57px);
    }
  }

  .hmy-form-section-vertical-files {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > p {
      align-self: flex-start;
    }
  }
  .hmy-form-section-vertical-file {
    display: flex;
    gap: 10px;
    align-items: center;

    .hmy-form-section {
      align-items: center;
    }
  }
}
