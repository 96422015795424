@use '../../assets/styles/variables' as *;

.sign-on-container {
  min-height: 100vh;
  width: 100%;
  background-color: $bgPrimary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    min-height: calc(100% - $menuBottomSize);
    padding-bottom: 50px;
  }
}

.sign-on-topbar-container {
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    height: 65px;
    width: 100%;
  }
}

.sign-on-topbar-container-logo {
  width: 50%;
  padding-left: 20px;
  padding-top: 10px;
  img {
    width: 200px;
    @media only screen and (max-width: 768px) {
      width: 150px;
    }
  }
}

.sign-on-container-content {
  height: calc(100vh - 500px);
  width: 400px;
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 150px auto 0 auto;
  row-gap: 40px;
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 130px);
    width: 100%;
    margin-top: 30px;
  }
  gap: 1rem;
}

.sign-on-container-message {
  height: calc(100vh - 500px);
  width: 400px;
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 150px auto 0 auto;
  row-gap: 40px;
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 130px);
    width: 100%;
    margin-top: 30px;
  }
}
