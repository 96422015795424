$registered: #5377ff;
$inprogress: #faad14;
$sent: #389e0d;
$received: #04225f;
$cancelled: #f5222d;

.status-text {
  width: auto;
  display: flex;
  align-items: center;
  column-gap: 6px;
  & > .status-badge {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

.status-text.status-1 {
  color: $registered;

  & > .status-badge {
    background-color: $registered;
  }
}

.status-text.status-4 {
  color: $received;

  & > .status-badge {
    background-color: $received;
  }
}

.status-text.status-2 {
  color: $inprogress;

  & > .status-badge {
    background-color: $inprogress;
  }
}

.status-text.status-3 {
  color: $sent;

  & > .status-badge {
    background-color: $sent;
  }
}

.status-text.status-5 {
  color: $cancelled;

  & > .status-badge {
    background-color: $cancelled;
  }
}
