@use '../../../../assets/styles/variables' as *;

.card-articles-container {
  width: 90%;
  background-color: $whiteColor;
  padding: 15px;
  border-radius: 25px;
  color: $primary;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  gap: 15px;

  .card-articles-description,
  .card-articles-quantity {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    font-size: 14px;
    position: relative;
  }
}
