@use '../../../assets/styles/variables' as *;

.order-confirmation-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 35px;

  .order-confirmation-modal-information {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    .order-confirmation-modal-information-box {
      border: 1px solid $primary;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 15px;
      padding: 15px;

      .order-confirmation-modal-information-phone {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 6px;

        & > input {
          border: none;
        }

        & > span {
          cursor: pointer;
        }
      }
      .order-confirmation-modal-information-phone-error {
        color: $redColor;
      }
    }
  }
}

.order-confirmation-modal-controls {
  display: flex;
  column-gap: 20px;
  justify-content: end;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  padding-block-start: 25px;
}
