@use '../../../../../assets/styles/variables' as *;

.grouping-file-card-container{
    width: 90%;
    background-color: $whiteColor;
    padding: 15px;
    border-radius: 25px;
    color: $primary;
    display: flex;
    flex-direction: column;
    font-size: 18px;

    .grouping-file-card-main{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 20px;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
        .grouping-file-card-date{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .grouping-file-card-document{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .grouping-file-card-edit-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            cursor: pointer;
        }
        .grouping-file-card-document-icon{
            border-radius: 50%;
            color: $whiteColor;
            background-color: $secondary;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 28px;
        }
        .grouping-file-card-document-icon-image{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}