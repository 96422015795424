@use '../../../assets/styles/variables' as *;

.landing-container-video {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
        width: 100%;
    }
}

@-webkit-keyframes tracking-in-contract-bck-top {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px) translateY(-300px);
              transform: translateZ(400px) translateY(-300px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract-bck-top {
    0% {
      letter-spacing: 1em;
      -webkit-transform: translateZ(400px) translateY(-300px);
              transform: translateZ(400px) translateY(-300px);
      opacity: 0;
      font-size: 80px;
      @media only screen and (max-width: 768px) {
        font-size: 40px;
     }
    }
    40% {
      opacity: 0.6;
    }
    50%{
        font-size: 90px;
        @media only screen and (max-width: 768px) {
            font-size: 50px;
        }
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
      font-size: 100px;
      @media only screen and (max-width: 768px) {
        font-size: 60px;
     }
    }
  }

.landing-welcome-video{
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    z-index: 0;
    position: relative;
    video{
        max-height: 100vh;
        width: 100%;
        @media only screen and (max-width: 768px) {
           display: none;
        }
    }
    .landing-welcome-text{
        position: absolute;
        width: 100%;
        top: 35%;
        left: 0%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $whiteColor !important;
        -webkit-animation: tracking-in-contract-bck-top 3s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-top 3s cubic-bezier(0.215, 0.610, 0.355, 1.000)  both;
            animation-delay: 2.5s;
        @media only screen and (max-width: 768px) {
            top: 40%;
            font-weight: 400;
            animation-delay: 0.5s;
        }

        .imageText{
            font-weight: 500 !important;
            @media only screen and (max-width: 768px) {
                font-weight: 400 !important;
                font-size: 40px !important;
            }
        }
    }

    @-webkit-keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

    .landing-welcome-next{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        color: $whiteColor;
        -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
	        animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.5s both;
        &:hover{
            font-size: 60px;
        }
    }
}