@use '../../../assets/styles/variables' as *;

.incidence-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  width: 600px !important;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    width: auto;
  }

  .incidence-modal-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.4em;
    padding-bottom: 15px;

    > :first-child {
      font-weight: bold;
    }
  }

  .incidence-modal-section-text {
    font-size: 1.2em;
    padding-block-start: 15px;
  }
}

.incidence-modal-controls {
  display: flex;
  column-gap: 20px;
  justify-content: end;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  padding-block-start: 25px;
}
