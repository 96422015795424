
@use '../../../assets/styles/variables' as *;

.login-form-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
}