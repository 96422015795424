@use '../../../assets/styles/variables' as *;

.user-edit-container{
    display: flex;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 40px;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
    }
}

.user-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 42px;
}

.user-edit-text{
    font-weight: 600 !important;
    font-size: 28px !important;
    color: $primary !important;
    @media only screen and (max-width: 768px) {
        font-size: 26px !important;
    }
}