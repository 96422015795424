@use '../../assets/styles/variables' as *;

.inputRounded .MuiOutlinedInput-root {
    border-radius: 50px;
    background-color: $whiteColor;
    padding-left: 20px;
    font-size: 20px !important;
    &:hover{
        box-shadow: 0px 6px 12px -8px rgba(36,36,36,1);
    }
    &.Mui-focused{
        box-shadow: 0px 6px 12px -8px rgba(36,36,36,1);
    }
    @media only screen and (max-width: 768px) {
        padding-left: 10px;
        font-size: 18px !important;
    }
}

.inputRounded .MuiInputBase-input {
    @media only screen and (max-width: 768px) {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.inputRounded .MuiFormLabel-root{
    font-size: 20px !important;
    font-weight: 500;
    @media only screen and (max-width: 768px) {
        font-size: 18px !important;
    }
}

.inputRounded .MuiOutlinedInput-notchedOutline {
    border-color: $whiteColor;
}

.MuiFormControl-root .MuiTextField-root .inputRounded{
    height: 100% !important;
}

.hover-icon{
    font-size: 30px !important;
    &:hover{
        cursor: pointer;
    }
}