.order-main-pdf {
  height: 100%;
  width: 19cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1cm;
  & .hmy-table-articles {
    display: flex !important;
  }
  & * {
    font-size: 90% !important;
  }
}
