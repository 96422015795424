@use '../../../assets/styles/variables' as *;

%icon-document-base {
    color: $whiteColor;
    border-radius: 50%;
    padding: 3px;
    width: 25px;
    height: 25px;
    font-size: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hmy-icon-document-novisible{
    @extend %icon-document-base;
    background-color: $redColor;  
}

.hmy-icon-document-visible{
    @extend %icon-document-base;
    background-color: $greenColor;
}

.hmy-icon-document{
    @extend %icon-document-base;
    background-color: $secondary;  
}