@use '../../assets/styles/variables' as *;

.not-found-container{
    height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: $navbarSize;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $smallNavbarSize;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .not-found-video-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50%;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        video{
            width: 50%;
        }
    }

    .not-found-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        @media only screen and (max-width: 768px) {
            width: 100%;
            margin-top: 20px;
        }
        .not-found-text-title{
            width: 90%;
            font-size: 70px;
            font-weight: 800;
            font-family: Raleway;
            color: $secondary;
            text-align: center;
            @media only screen and (max-width: 768px) {
                width: 98%;
                font-size: 40px;
                font-weight: 700;
            }
        }
        .not-found-text-body{
            width: 90%;
            font-size: 26px;
            font-weight: 400;
            color: $whiteColor;
            text-align: center;
            margin-bottom: 20px;
            @media only screen and (max-width: 768px) {
                width: 85%;
                font-size: 22px;
                margin-bottom: 10px;
            }
        }

        .not-found-btn{
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}