@use '../../../assets/styles/variables' as *;

.card-order-container {
  width: 90%;
  background-color: $whiteColor;
  padding: 15px;
  border-radius: 25px;
  color: $primary;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  gap: 15px;

  .MuiBadge-badge {
    font-size: 0.8em;
    background: #eb3232 !important;
    position: absolute;
    top: 6px;
    height: 17px;
    min-width: 14px;
    color: white;
  }

  .card-order-number {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.card-order-status {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-order-buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
}

.card-order-isClosed {
  background-color: $footerGrey;
}

.delete-icon {
  &:hover {
    cursor: pointer;
    color: $redColor;
  }
}

.error-icon {
  color: $redColor;
  font-size: 60px !important;
}

.isDeleted {
  color: $redColor;
}
