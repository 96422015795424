@use '../../assets/styles/variables' as *;

.footer{
    background-color: $whiteColor;
    font-size: 18px;
    color: $blackColor;
    width: 100vw;
    @media only screen and (max-width: 768px) {
        width: 100vw;
        padding-bottom: $smallNavbarSize;
        box-shadow: inset 0px -11px 13px -9px rgba(36,36,36,1);
    }
}

.footer-container{
    width: calc(100% - 280px);
    padding: 30px 140px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 20px;

        .footer-logo{
            order: 1;
        };
        .generalReferences{
            order: 3;
        };
        .iconsReferences{
            order: 2;
        }
    }
}

.footer-logo{
    width: 150px;
    @media only screen and (max-width: 768px) {
        width: 120px;
    }
}

.generalReferences {
    display: flex;
    ul{
        display: flex;
        list-style: none;
        gap: 30px;
        padding: 0px !important;
        font-size: 18px;
        color: $primary;
        @media only screen and (max-width: 768px) {
            gap: 5px;      
            font-size: 16px; 
        }
    }
    li a:hover{
        text-decoration: underline;
    }
}

.iconsReferences{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $primary;
    ul{
        padding-left: 0 !important;
        margin: 7px;
        width: 100%;
        display: flex;
        list-style: none;
        flex-direction: row;
        justify-content: space-around;
    }
    .footer-icon-style{
        width: 35px;
        &:Hover{
            cursor: pointer;
        }
    }
}