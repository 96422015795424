@use '../../../assets/styles/variables' as *;

.grouping-file-container {
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    .grouping-file-btn-up{
        display: none;
        position: fixed;
        @media only screen and (max-width: 768px) {
            bottom: calc($menuBottomSize + 10px);
            right: 10px;
            width: 45px;
            height: 45px;
            background-color: $tertiary;
            z-index: 1;
            display: flex;
        }
        a{
            display: flex;
            align-items: center;
            color: $bgPrimary;
        }
    }
}

.grouping-file-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
        margin-top: 10px;
        width: 95%;
        padding-bottom: 10px;
    }
}