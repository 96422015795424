.hmy-images-viewer-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}