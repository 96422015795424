@use '../../../../../assets/styles/variables' as *;

.grouping-file-table-row-element{
    color: $primary;
    display: flex;
    align-items: center;
    height: 60%;
}

.grouping-file-table-projects{
    border-radius: 25px;
    background-color: $secondary;
    color: $whiteColor;
    padding: 5px 20px;
    cursor: pointer;
}

.grouping-file-edit-icon{
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grouping-file-document-icon{
    border-radius: 50%;
    color: $whiteColor;
    background-color: $secondary;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grouping-file-content-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 15px;
    img{
        width: 70px;
        height: 70px;
    }
    span{
        font-size: 22px;
    }
}

.grouping-file-content-modal-close{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 30px;
}