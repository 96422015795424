@use '../../assets/styles/variables' as *;

.incidence-container {
  min-height: calc(100vh - $navbarSize);
  width: 100%;
  background-color: $bgPrimary;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: $navbarSize;
  align-items: center;
  justify-content: start;
  @media only screen and (max-width: 768px) {
    min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
    margin-top: $smallNavbarSize;
    justify-content: flex-start;
  }

  .incidence-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $primary;
    cursor: pointer;
    .MuiSvgIcon-root {
      font-size: 50px !important;
    }
    .incidence-title-text {
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      color: $primary;
      @media only screen and (max-width: 768px) {
        font-size: 25px;
      }
    }
    .incidence-title-text-incidence {
      margin-left: 10px;
      font-size: 26px;
      font-weight: 500;
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        margin-left: 0px;
      }
    }
  }

  .incidence-body {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 40px;
    width: 85%;
    @media only screen and (max-width: 768px) {
      margin-top: 0;
      width: 85%;
      flex-direction: column-reverse;
      font-size: 0.6em;
    }

    .incidence-main {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      & > span {
        font-size: 1.2em;
        font-weight: bold;
      }

      .incidence-description {
        padding: 12px;
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 10px;
      }
    }
    .incidence-info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 40%;

      & > span {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }
  .incidence-articles-list {
    max-height: 40vh;
    overflow: auto;

    li {
      padding: 4px 0;
      align-items: center;
      gap: 10px;
    }
  }

  .incidence-image-list {
    max-height: 40vh;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    img {
      max-width: 100%;
      height: 100px;
    }
  }

  .incidence-comments {
    .incidence-comments-add {
      padding-block-end: 30px;
      .incidence-comments-add-buttons {
        padding-block-start: 10px;
        display: flex;
        column-gap: 10px;
      }
    }
  }
}
