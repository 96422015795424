@use '../../../../assets/styles/variables' as *;

.login-form-recovery-content {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 70px;
    animation: fade-in 1.2s linear both;
    @media only screen and (max-width: 768px) {
        gap: 35px;
    }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.login-form-recovery-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.recovery-inputEmail .MuiInputBase-input{
    font-size: 20px !important;
}

.recovery-inputEmail .MuiFormLabel-root{
    font-size: 20px !important;
}

.login-form-recovery-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

%login-form-recovery-send-base{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    border-radius: 25px;
    font-size: 24px;
    transition: all .1s ease-in-out;
}
    

.login-form-recovery-send{
    @extend %login-form-recovery-send-base;
    background-color: $whiteColor;
    color: $primary;
    cursor: pointer;
    &:hover{
        padding: 12px 42px;
        font-size: 26px;
    }
}

.login-form-recovery-send-disabled {
    @extend %login-form-recovery-send-base;
    background-color: $footerGrey;
    color: $whiteColor;
}

.login-form-recovery-text{
    font-size: 20px !important;
}

.login-form-recovery-goback{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
        margin-top: 10px;
    }
}