@use '../../../assets/styles/variables' as *;

.no-orders-container {
  height: calc(100vh - $navbarSize);
  width: 100%;
  background-color: $bgPrimary;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: $navbarSize;
  @media only screen and (max-width: 768px) {
    height: calc(100vh - $menuBottomSize - $smallNavbarSize);
    margin-top: $smallNavbarSize;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

.no-orders-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  img {
    width: 100%;
    @media only screen and (max-width: 768px) {
      height: 300px;
      width: auto;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.no-orders-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .text-title {
    width: 90%;
    font-size: 45px;
    font-weight: 600;
    color: $secondary;
    text-align: center;
    @media only screen and (max-width: 768px) {
      width: 98%;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .text-body {
    width: 70%;
    font-size: 22px;
    font-weight: 400;
    color: $blackColor;
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      width: 85%;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

.no-orders-image-customer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.no-orders-image-customer-container {
  width: 500px;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fbe3a7;
  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
  .no-orders-image-customer-circle {
    background-color: #fbce7b;
    border-radius: 50%;
    animation-duration: 1.5s;
    animation-name: growCirlce;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    @media only screen and (max-width: 768px) {
      animation-name: growCirlceSmall;
    }
  }
  img {
    position: absolute;
    bottom: -26px;
    width: 80%;
    @media only screen and (max-width: 768px) {
      height: 200px;
      width: auto;
      bottom: -15px;
    }
  }
}

@keyframes growCirlce {
  from {
    width: 270px;
    height: 270px;
    @media only screen and (max-width: 768px) {
      width: 170px;
      height: 170px;
    }
  }

  to {
    width: 350px;
    height: 350px;
    @media only screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
    }
  }
}

@keyframes growCirlceSmall {
  from {
    width: 120px;
    height: 120px;
  }

  to {
    width: 180px;
    height: 180px;
  }
}

.no-orders-text-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .customer-text-title {
    width: 90%;
    font-size: 45px;
    font-weight: 600;
    color: $secondary;
    text-align: center;
    @media only screen and (max-width: 768px) {
      width: 98%;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .customer-text-body {
    width: 70%;
    font-size: 22px;
    font-weight: 400;
    color: $blackColor;
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      width: 85%;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
