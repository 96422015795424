@use '../../../../assets/styles/variables' as *;

.project-settings-users{
    width: 45%;
    background-color: $whiteColor;
    color: $primary;
    border-radius: 15px;
    box-shadow: 0px 4px 6px -8px rgba(36,36,36,1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media only screen and (max-width: 768px) {
        padding: 15px 10px;
        width: calc(100% - 20px);
    }
}

.project-settings-users-title-container{
    width: 100%;
    background-color: $whiteColor;
    color: $primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;           
    .project-settings-users-title{
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.project-settings-icon-edit{
    cursor: pointer;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-settings-users-list-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .project-settings-user-name{
        font-size: 18px;
        font-weight: 600;
    }
    .project-settings-user-profile{
        margin-top: 5px;
        font-size: 18px;
    }
}