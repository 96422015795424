@use '../../assets/styles/variables' as *;

.orders-container {
  min-height: calc(100vh - $navbarSize);
  width: 100%;
  background-color: $bgPrimary;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: $navbarSize;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 768px) {
    min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
    margin-top: $smallNavbarSize;
    justify-content: flex-start;
  }
  .orders-btn-up {
    display: none;
    position: fixed;
    @media only screen and (max-width: 768px) {
      bottom: calc($menuBottomSize + 10px);
      right: 10px;
      width: 45px;
      height: 45px;
      background-color: $tertiary;
      z-index: 1;
      display: flex;
    }
    a {
      display: flex;
      align-items: center;
      color: $bgPrimary;
    }
  }
}

.orders-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
  .hmy-table-header .hmy-table-header-element {
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
}
.orders-container-full .orders-main {
  width: 95%;
  margin-top: 50px;
}
.orders-filters {
  height: 66px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
}
.orders-filters-container {
  height: 66px;
  width: 70%;
  display: grid;
  flex-direction: row;
  column-gap: 20px;
  grid-template-columns: 1fr 130px 130px 1fr;
  align-items: center;
  background-color: $bgSecondary;
  border-radius: 50px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: none;
    height: auto;
    gap: 20px;
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & input {
    border-radius: 50px;
    padding-inline: 25px;
    padding-block: 10px;
    font-size: 16px;
    font-family: Roobert;
    border: 1px solid #ccc;

    &:focus-visible {
      border-color: #999;
      outline: none;
    }
  }

  & #hmy-select-label {
    font-size: 16px;
  }

  & .hmy-selectable > div {
    font-size: 18px;
    padding-block: 6px !important;
  }
  .hmy-modal-order-filters-search {
    margin-left: 15px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-right: 20px;

    @media only screen and (max-width: 1024px) {
      input {
        width: 100px;
        font-size: 12px;
      }
    }
  }
}
.orders-filters-buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 20px;
  justify-content: space-between;
  .orders-filters-buttons-clear {
    font-size: 16px;
    cursor: pointer;
    justify-self: flex-start;
    color: $primary;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    column-gap: 6px;
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
  @media only screen and (max-width: 1024px) {
    align-self: flex-end;
  }
}
.orders-container-full .orders-filters-container {
  grid-template-columns: 1fr 1fr 130px 130px 1fr;
  width: 80%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr 1fr 100px 100px 1fr;
  }
}
.orders-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  width: 97%;
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 20px;
    padding-left: 10px;
    width: calc(97% - 10px);
  }
  .switch-show-closed {
    margin-left: 20px;
  }

  .MuiTypography-root {
    font-size: 18px;
    font-weight: 400;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      font-weight: 300;
    }
  }
}
