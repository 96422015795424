@use '../../assets/styles/variables' as *;

.hmy-paginator{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    @media only screen and (max-width: 768px) {
        margin-top: 45px;
    }
    .stepper-horizontal {
        display:flex;
        width:90%;
        flex-direction: row;
    }
    .stepper-horizontal-circle{
        width:10px;
        position: relative;
        .circle{
            width:5px;
            height:5px;
            position: absolute;
            bottom: -4px;
            border-radius: 50%;
            border: 3px solid $primary;
        }
    }

    .step {
        border-bottom: 2px solid $primary;   
        position: relative;  
        &:hover{
            cursor: pointer;
            .step-title{
                display: flex;
            }
            .step-circle{
                display: flex;
            }
        }

        .step-circle {
            width:12px;
            height:12px;
            border-radius: 50%;
            background-color: $tertiary;
            position: absolute;
            bottom: -7px;
            right: 10%;
            display: none;
        }
    
        .step-title {
            display: none;
            margin-bottom:5px;
            font-size:18px;
            font-weight:400;
            position: absolute;
            bottom: 2px;
            right: 10%;
            @media only screen and (max-width: 768px) {
                bottom: -3px;
            }
        }
    }

    .active {
        .step-title{
            display: flex;
        }
        .step-circle{
            display: flex;
        }
    }

    .basic-paginator{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        width: 100%;
        height: 30px;
        justify-content: center;
        align-items: center;
        color: $footerGrey;
        font-size: 20px;
        .selectable-pages{
            padding: 10px;     
        }
        .active{
            color: $primary;
            &:hover{
                cursor: pointer;
                font-size: 24px;
            }
        } 
        .pages{
            padding: 0px 15px;
            border-right: 1px solid $primary;
            border-left: 1px solid $primary;
            color: $primary;
        }
        .next{
            padding: 10px;
        }
    }
}