@use '../../assets/styles/variables' as *;

.hmy-calendar-container{
    padding: 14px;
    background-color: inherit;
    color: $primary;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-radius: 50px;
    position: relative;
    // &:hover{
    //     box-shadow: 0px 8px 17px -8px rgba(36,36,36,1);
    // }
    @media only screen and (max-width: 768px) {
        padding: 10px;
    }
    input[type="date"] {
        display: none;
    }

    .hmy-calendar-text{
        @media only screen and (max-width: 768px) {
            font-size: 20px !important;
            font-weight: 500;
        }
    }
}