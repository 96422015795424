@use '../../../../assets/styles/variables' as *;

.no-groupings-container {
    width: 100%;
    background-color: $whiteColor;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 30px;
    padding: 50px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.no-groupings-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    .text-title{
        width: 90%;
        font-size: 20px;
        font-weight: 500;
        color: $blackColor;
        text-align: center;
        @media only screen and (max-width: 768px) {
            width: 98%;
            font-size: 20px;
        }
    }
}