@use '../../../../assets/styles/variables' as *;

.user-form-element-content-item-loaded{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 35px;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
    }
}

.user-form-element-content-checkboxes{
    font-size: 22px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
}

.user-form-element-content-email{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
    .user-form-element-email{
        display: flex;
        flex-grow: 1;
        font-size: 26px;
    }
    .user-form-element-span{
        display: flex;
        font-size: 22px;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
}

.user-new-error-message{
    color: $redColor;
    font-size: 18px;
}
.user-form-inspiration-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.user-form-title{
    font-weight: 600 !important;
    font-size: 30px !important;
    color: $primary !important;
}