@use '../../assets/styles/variables' as *;

.management-container {
    min-height: calc(100vh - $navbarSize);
    width: 100%;
    background-color: $bgPrimary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: $navbarSize;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - $menuBottomSize - $smallNavbarSize);
        margin-top: $menuBottomSize;
    }

    .management-main {
        height: 100%;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        @media only screen and (max-width: 768px) {
            width: 95%;
            padding-bottom: 10px;
        }
    
        .content-box-management{
            width: 100%;
            margin-top: -30px;
        }
    }
}