@use '../../../assets/styles/variables' as *;

.incidence-status {
  display: flex;
  align-items: center;

  .incidence-status-button {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $primary;
    color: #666;
    padding: 6px 16px;
    font-size: 0.9em;

    &:hover {
      background-color: #ccc;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
      border-right: none;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
      border-left: none;
    }
    &.selected,
    &:hover {
      background-color: $primary;
      color: white;
    }
    /*&.state-1.selected,
    &.state-1:hover {
      background-color: #00803733;
      color: $greenColor;
    }

    &.state-2.selected,
    &.state-2:hover {
      background-color: #384cff33;
      color: $secondary;
    }

    &.state-3.selected,
    &.state-3:hover {
      background-color: #d8002733;
      color: $redColor;
    }*/
  }
}
