@use '../../assets/styles/variables' as *;
.hmy-date-container {
  .hmy-calendar-filter-container {
    padding: 14px;
    background-color: inherit;
    color: $primary;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
    width: 130px;

    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
    input[type='date'] {
      display: none;
    }

    .hmy-calendar-filter-text {
      font-size: 18px !important;
      @media only screen and (max-width: 768px) {
        font-size: 14px !important;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 14px !important;
      }
    }
  }

  .react-datepicker {
    position: absolute;
    top: 110px;
    z-index: 2;
  }
}
