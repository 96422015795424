@use '../../../../assets/styles/variables' as *;

.articles-table-row-element {
  color: $primary;
  display: flex;
  align-items: center;
  height: 60%;
  box-sizing: border-box;
  font-size: 0.8em;

  .image {
    width: 50px;
    height: 50px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
  }
  .image-pdf {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 60px;
      height: 60px;
      color: #c90303;
    }
  }
}

.delete-icon {
  &:hover {
    cursor: pointer;
    color: $redColor;
  }
}

.error-icon {
  color: $redColor;
  font-size: 60px !important;
}

.isClosed {
  color: $footerGrey;
}

.isDeleted {
  color: $redColor;
}
