@use '../../../assets/styles/variables' as *;

.project-Settings-container{
    width: 100%;
    padding-bottom: 30px;
    margin-top: 30px;
    font-size: 24px;
    @media only screen and (max-width: 768px) {
        margin-top: 60px;
        font-size: 20px;
    }
}

.project-Settings-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    @media only screen and (max-width: 768px) {
        gap: 15px;
        align-items: center;
    }
}

.project-Settings-users-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;
        margin-top: 15px;
    }
}