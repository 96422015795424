/*Background colors*/

$bgPrimary: #F0F4FF;

$primary: #04225F;
$secondary: #384CFF;
$tertiary: #FFAAAA;

$whiteColor: #FFFFFF;
$blackColor: #000000;

$redColor: #D80027;
$greenColor: #008037;

$bgSecondary: #fff;
$bgTertiary: #53565A;
$bgPrimaryLight: #4EC3E0;
$bgSecondaryLight: #fff;
$bgTertiaryLight: #B1B3B3;


$menuBottomSize: 70px;
$navbarSize: 90px;
$smallNavbarSize: 70px;

$footerBackColor: #f5f5f7;
$footerGrey: #B2B2B2;