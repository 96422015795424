.flip-component-container {
    width: 100%;
    height: 100%;

    .swiper {
        height: 100%;
        width: 100%;

        .swiper-wrapper {
            height: 100%;
            width: 100%;

            .flip-slide-container.swiper-slide {
                height: 100%;
                width: 100%;
            }
        }
    }
}