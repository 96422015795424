.grouping-file-search{
    height: 70px;
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 50px;
    }
}

.grouping-files-types{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 22px;
}