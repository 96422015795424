@use '../../assets/styles/variables' as *;

.multiple-string{
    border: 1px solid $primary;
    padding: 7px 10px;
    border-radius: 25px;
    cursor: pointer;
    &:hover{
        background-color: $primary;
        color: $whiteColor;
    }
}