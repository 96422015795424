.sign-on-link-container {
  background-color: #ffffff;
  color: #04225f;
  border-radius: 10px;
  box-shadow: 0px 4px 6px -8px rgb(36, 36, 36);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: flex-start;
  width: 89%;

  .sign-on-link-header {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .sign-on-link-body {
    display: flex;
    column-gap: 20px;
    align-items: baseline;

    span {
      padding-bottom: 6px;
      border-bottom: 1px solid #999;
      color: #999;
    }

    button {
      color: #04225f;
      border: none;
    }
  }
}

.sign-on-link-body.mobile {
  display: flex;
  justify-content: center;
  padding: 0 0;
}
