.images-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    @media only screen and (max-width: 768px) {
        align-items: center;
    }
}

.images-seeHidden{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 10px;
    @media only screen and (max-width: 768px) {
        margin-top: 35px;
        padding-left: 15px;
    }

    .images-seeHidden-text{
        font-size: 18px;
        margin-top: -5px;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
            margin-top: -3px;
        }
    }
}

.project-image-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    margin-bottom: 5px;
}

.project-image-image{
    height: 300px;
    width: 24.5%;
    position: relative;
    transition: all .1s ease-in-out;
    border-radius: 5px;
    &:hover{
        width: 25%;
        height: 305px;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: 0px 8px 17px -8px rgba(36,36,36,1);
    }
}

.project-image-small{
    height: 100px;
    width: 30%;
    position: relative;
}

.project-image-card-overlay{
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.project-image-visibility{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
    @media only screen and (max-width: 768px) {
        bottom: 5px;
        left: 5px;
    }
}

.project-image-launch{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    @media only screen and (max-width: 768px) {
        bottom: 5px;
        right: 5px;
    }
}

.project-image-seeMore{
    margin-top: 20px;
    width: 100%;
    @media only screen and (max-width: 768px) {
        width: 92%;
    }
}