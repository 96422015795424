@use '../../assets/styles/variables' as *;

.nav-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  height: $navbarSize;
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
  padding-left: 50px;
  background-color: $bgPrimary;
  z-index: 5 !important;
  @media only screen and (max-width: 768px) {
    height: $smallNavbarSize;
    width: calc(100% - 17px);
    padding-left: 17px;
  }
}

.back-fixed {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
}

.nav-bar-logo {
  width: 200px;
  @media only screen and (max-width: 768px) {
    width: calc(100vw - 30px);
    img {
      width: 170px;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}
.nav-bar-shopping-cart {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
}
.nav-bar-tabs-container {
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.nav-bar-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  list-style: none;
  width: 100%;
  li {
    text-decoration: none;
    color: $primary;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 500;
    margin-right: 5px;
    &:hover {
      cursor: pointer;
      a {
        padding-bottom: 5px;
        border-bottom: 1px solid $primary;
      }
    }
    a:link,
    a:visited,
    a:active {
      text-decoration: none;
      color: inherit;
    }
  }
}

@keyframes selected-tab {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.nav-bar-tab-active {
  color: $secondary !important;
  font-size: 22px !important;
  font-weight: 500;
  a {
    padding-bottom: 5px;
    border-bottom: 2px solid $primary !important;
    animation: selected-tab 1.2s;
  }
}
.nav-bar-profile {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
