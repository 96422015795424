@use '../../assets/styles/variables' as *;

.textarea-container {
  width: 100%;
  .inputTextArea {
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: #1c2025;
    background: '#fff';
    border: 1px solid #dae2ed;
    box-shadow: 0px 2px 2px #f3f6f9;
    resize: none;

    &:hover {
      border-color: #3399ff;
    }

    &:focus {
      border-color: #3399ff;
      box-shadow: 0 0 0 3px #b6daff;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
    &.readOnly {
      color: #333 !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      cursor: default;
      resize: none;
    }
  }

  .textarea-length {
    display: flex;
    justify-content: end;
    color: #aaa;
    font-size: 0.8em;
  }
}
