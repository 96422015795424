@use '../../assets/styles/variables' as *;

.menu-bottom-container {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $menuBottomSize;
  background-color: $whiteColor;
  justify-content: center;
  align-items: center;
  z-index: 3;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
}

.indicator {
  position: absolute;
  top: -45%;
  width: 55px;
  height: 55px;
  background: $whiteColor;
  border-radius: 50%;
  border: 6px solid $bgPrimary;
  transition: 0.5s;
}

%menu-bottom-li-base {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}

%menu-bottom-link-base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

%menu-bottom-icon-base {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5rem;
  text-align: center;
  transition: 0.5s;
  color: $blackColor;
  img {
    height: 40px;
    width: 40px;
  }
}

%menu-bottom-text-base {
  position: absolute;
  color: $blackColor;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}

/*Menu 6 elementos*/

.menu-bottom-container-list-6 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 2px;
  padding-left: calc((100% - 5px - (70px * 6)) / 2);
  padding-right: calc((100% - 5px - (70px * 6)) / 2);
}

.menu-bottom-container-list-6 li {
  @extend %menu-bottom-li-base;
}

.menu-bottom-container-list-6 li a {
  @extend %menu-bottom-link-base;
}

.menu-bottom-container-list-6 li a .icon {
  @extend %menu-bottom-icon-base;
}

.menu-bottom-container-list-6 li.active a .icon {
  transform: translateY(-25px);
}

.menu-bottom-container-list-6 li a .text {
  @extend %menu-bottom-text-base;
}

.menu-bottom-container-list-6 li.active a .text {
  opacity: 1;
  transform: translateY(15px);
}

.menu-bottom-container-list-6 li:nth-child(1).active ~ .indicator {
  transform: translateX(1px);
}

.menu-bottom-container-list-6 li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(73px * 1));
}

.menu-bottom-container-list-6 li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(73px * 2));
}

.menu-bottom-container-list-6 li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(72.2px * 3));
}

.menu-bottom-container-list-6 li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(72.3px * 4));
}
.menu-bottom-container-list-6 li:nth-child(6).active ~ .indicator {
  transform: translateX(calc(72.3px * 5));
}

/*Menu 5 elementos*/

.menu-bottom-container-list-5 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 2px;
  padding-left: calc((100% - 5px - (70px * 5)) / 2);
  padding-right: calc((100% - 5px - (70px * 5)) / 2);
}

.menu-bottom-container-list-5 li {
  @extend %menu-bottom-li-base;
}

.menu-bottom-container-list-5 li a {
  @extend %menu-bottom-link-base;
}

.menu-bottom-container-list-5 li a .icon {
  @extend %menu-bottom-icon-base;
}

.menu-bottom-container-list-5 li.active a .icon {
  transform: translateY(-25px);
}

.menu-bottom-container-list-5 li a .text {
  @extend %menu-bottom-text-base;
}

.menu-bottom-container-list-5 li.active a .text {
  opacity: 1;
  transform: translateY(15px);
}

.menu-bottom-container-list-5 li:nth-child(1).active ~ .indicator {
  transform: translateX(1px);
}

.menu-bottom-container-list-5 li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(73px * 1));
}

.menu-bottom-container-list-5 li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(73px * 2));
}

.menu-bottom-container-list-5 li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(72.2px * 3));
}

.menu-bottom-container-list-5 li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(72.3px * 4));
}

/*Menu 4 elementos*/
.menu-bottom-container-list-4 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  padding-left: calc((100% - 40px - (70px * 4)) / 2);
  padding-right: calc((100% - 40px - (70px * 4)) / 2);
}

.menu-bottom-container-list-4 li {
  @extend %menu-bottom-li-base;
}

.menu-bottom-container-list-4 li a {
  @extend %menu-bottom-link-base;
}

.menu-bottom-container-list-4 li a .icon {
  @extend %menu-bottom-icon-base;
}

.menu-bottom-container-list-4 li.active a .icon {
  transform: translateY(-25px);
}

.menu-bottom-container-list-4 li a .text {
  @extend %menu-bottom-text-base;
}

.menu-bottom-container-list-4 li.active a .text {
  opacity: 1;
  transform: translateY(15px);
}

.menu-bottom-container-list-4 li:nth-child(1).active ~ .indicator {
  transform: translateX(1px);
}

.menu-bottom-container-list-4 li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(91px * 1));
}

.menu-bottom-container-list-4 li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(90px * 2));
}

.menu-bottom-container-list-4 li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(90px * 3));
}

/*Menu 3 elementos*/
.menu-bottom-container-list-3 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 35px;
  padding-left: calc((100% - 52.5px - (70px * 3)) / 2);
  padding-right: calc((100% - 52.5px - (70px * 3)) / 2);
}

.menu-bottom-container-list-3 li {
  @extend %menu-bottom-li-base;
}

.menu-bottom-container-list-3 li a {
  @extend %menu-bottom-link-base;
}

.menu-bottom-container-list-3 li a .icon {
  @extend %menu-bottom-icon-base;
}

.menu-bottom-container-list-3 li.active a .icon {
  transform: translateY(-25px);
}

.menu-bottom-container-list-3 li a .text {
  @extend %menu-bottom-text-base;
}

.menu-bottom-container-list-3 li.active a .text {
  opacity: 1;
  transform: translateY(15px);
}

.menu-bottom-container-list-3 li:nth-child(1).active ~ .indicator {
  transform: translateX(1px);
}

.menu-bottom-container-list-3 li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(105px * 1));
}

.menu-bottom-container-list-3 li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(105.5px * 2));
}

/*Menu 2 elementos*/
.menu-bottom-container-list-2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 30px;
  padding-left: calc((100% - 40px - (70px * 2)) / 2);
  padding-right: calc((100% - 40px - (70px * 2)) / 2);
}

.menu-bottom-container-list-2 li {
  @extend %menu-bottom-li-base;
  width: 100px;
  height: 70px;
}

.menu-bottom-container-list-2 li a {
  @extend %menu-bottom-link-base;
}

.menu-bottom-container-list-2 li a .icon {
  @extend %menu-bottom-icon-base;
}

.menu-bottom-container-list-2 li.active a .icon {
  transform: translateY(-25px);
}

.menu-bottom-container-list-2 li a .text {
  @extend %menu-bottom-text-base;
}

.menu-bottom-container-list-2 li.active a .text {
  opacity: 1;
  transform: translateY(15px);
}

.menu-bottom-container-list-2 li:nth-child(1).active ~ .indicator {
  transform: translateX(16px);
}

.menu-bottom-container-list-2 li:nth-child(2).active ~ .indicator {
  transform: translateX(147px);
}
