@use '../../../assets/styles/variables' as *;

.hmy-icon-launch{
    color: $whiteColor;
    border-radius: 50%;
    padding: 3px;
    width: 25px;
    height: 25px;
    font-size: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $secondary;  
}