@use '../../../../assets/styles/variables' as *;

.drawing-card-container{
    width: 28%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $whiteColor;
    height: 250px;
    transition: all .2s ease-in-out;
    @media only screen and (max-width: 768px) {
        height: 170px;
        width: 89%;
        margin-right: 0%;
    }
    .drawing-card{
        width: 100%;
        background-color: $whiteColor;
        display: flex;
        flex-direction: column;
        color: $primary;
        height: 100%;
        position: relative;
        font-size: 20px;
    }

    .drawing-card-overlay{
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .drawing-card-visibility{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        left: 10px;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            bottom: 5px;
            left: 5px;
        }
    }

    .drawing-card-launch{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            bottom: 5px;
            right: 5px;
        }
    }

    .drawing-card-name{
        display: none;
        flex-direction: row;
        padding: 10px;
        font-size: 20px;
    }

    &:hover{
        box-shadow: 0px 8px 8px -8px rgba(36,36,36,1);
        padding: 10px;
        border-radius: 15px;
        .drawing-card-name{
            display: flex;
        }
    }
}