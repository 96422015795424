@use '../../assets/styles/variables' as *;

.first{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.last{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 5px 8px -8px rgba(36,36,36,1);
}
.hmy-table-row{
    background-color: $whiteColor;
    font-size: 18px;
    margin-top: 7px;
    height: 50px;
    display: flex;
    flex-direction: row;
    width: 98%;
    align-items: center;
    transition: all .2s ease-in-out;
    &:hover{
        height: 55px;
        width: 100%;
    }
}