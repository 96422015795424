@use '../../../assets/styles/variables' as *;

.import-projects-selected-box{
    width: 98%;
    display: flex;
    align-items: center;
    background-color: $secondary;
    color: $whiteColor;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 20px;
    animation: scale-up-center 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    @media only screen and (max-width: 768px) {
        width: 90%;
        margin-bottom: 15px;
    }
    .import-projects-selected-box-text{
        flex-grow: 1;
        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
    .import-projects-selected-box-import {
        background-color: $whiteColor;
        color: $greenColor;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px;
        &:hover{
            background-color: $greenColor;
            color: $whiteColor;
        }
        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
    .import-projects-selected-box-cancel {
        background-color: $whiteColor;
        color: $redColor;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px;
        &:hover{
            background-color: $redColor;
            color: $whiteColor;
        }
        @media only screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
}

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }