@use '../../../../../assets/styles/variables' as *;

.hmy-table-incidences {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  font-size: 22px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.hmy-table-incidences-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px !important;
  margin-top: 30px;
  background-color: $primary;
  align-items: center;
  border-radius: 15px;
  .hmy-table-incidences-header-element {
    color: $whiteColor;
    display: flex;
    align-items: center;
    font-size: 18px;
    height: 60%;
  }
  .separator {
    border-right: 2px solid $whiteColor;
  }
}

.hmy-table-incidences-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 10px;
}

.hmy-table-incidences-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 65px !important;
  background-color: $primary;
  align-items: center;
  border-radius: 15px;
  color: $whiteColor;
  font-size: 18px;
  height: 60%;
  padding-right: 40px;
  box-sizing: border-box;
}
