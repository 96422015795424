@use '../../assets/styles/variables' as *;

.shopping-menu-modal .MuiPaper-root.MuiPaper-elevation {
  position: absolute;
  right: 0;
  top: 50px;

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    border-radius: 0 !important;
  }
}
.shopping-menu-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  width: 400px;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
}

.shopping-menu-modal-total {
  display: flex;
  justify-content: flex-end;
  padding-block: 14px;
  font-size: 22px;
  color: $primary;
  width: 100%;
}

.shopping-menu-modal-controls {
  display: flex;
  column-gap: 20px;
  justify-content: end;
  align-items: center;
  width: 100%;
  align-self: flex-end;
  padding-block-start: 25px;
}

.shopping-menu-modal-catalogue-line {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: $bgPrimary;
  width: calc(100% - 20px);
  margin-bottom: 10px;

  .image {
    width: 80px;
    height: 80px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 10px;
    cursor: pointer;
  }

  .image-pdf {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 60px;
      height: 60px;
      color: #c90303;
    }
  }

  .shopping-menu-modal-catalogue-line-description {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .shopping-menu-modal-catalogue-line-description-item {
      font-weight: bold;
    }

    .shopping-menu-modal-catalogue-line-description-more {
      color: $primary;
      cursor: pointer;
    }
  }

  .shopping-menu-modal-catalogue-line-price {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    color: $primary;
    font-size: 18px;

    .shopping-menu-modal-catalogue-line-price-controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 10px;
      align-items: center;
      background-color: #eee;
      font-weight: bold;
      padding: 3px 5px;
      font-size: 16px;
    }
  }
}
