.inbox-list-documents-container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 0;

    .inbox-list-documents-filter{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .inbox-list-documents-search{
            width: 100%;
        }
        .inbox-list-documents-options{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            @media only screen and (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                padding-left: 10px;
            }
            .inbox-list-documents-selects{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                @media only screen and (max-width: 768px) {
                    justify-content: space-between;
                    width: calc(100% - 10px);
                }
            }
            .inbox-list-documents-seeHidden{
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                font-size: 24px;
                @media only screen and (max-width: 768px) {
                    margin-top: 15px;
                    font-size: 18px;
                }
            }
        }
    }
    .inbox-list-documents{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.inbox-switch-see-hidden .MuiTypography-root{
    font-size: 18px;
    font-weight: 400;
    @media only screen and (max-width: 768px) {
        font-size: 18px;
        font-weight: 300;
    }
}