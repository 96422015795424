@use '../../../assets/styles/variables' as *;

.home-section-carousel{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    background-color: $whiteColor;
    background-size: 100% 100%;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
        height: calc(100vh - $menuBottomSize);
    }

    .hmy-btn-container{
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 768px) {
            align-items: center;
            height: 100px;
            padding-bottom: 5px;
        }
    }
}

.home-carousel-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    width: 100%;
    height: 80%;
    position: relative;
    @media only screen and (max-width: 768px) {
        display: flex;
    }

    .swiper {
      width: 100%;
      padding-bottom: 50px;
    }
    
    .swiper-slide {
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      height: 90%;
    }

    .swiper-pagination-mybullet{
        width: 20px;
        height: 20px;
        color: #000 !important;
    }

    .swiper-pagination-bullet-active{
        color: #fff !important;
        background-color: $secondary !important;
    }
}

  .home-carousel-front {
    width: 900px;
    position: absolute;
    box-shadow: 0px 5px 10px -8px rgba(36,36,36,1);
    display: flex;
    transition: all .2s ease-in-out;
    &:hover{
        cursor: pointer;
        width: 905px;
        .home-carousel-front-project-name{
            font-size: 62px;
        }
    }
    .home-carousel-front-container{
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        img{
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .home-carousel-front-project-name-opacity{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.4;
    }

    .home-carousel-front-project-name-container{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-carousel-front-project-name{
        color: white;
        font-size: 60px;
        font-weight: 900;
        transition: all .2s ease-in-out;
    }
}