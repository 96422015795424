@use '../../../../assets/styles/variables' as *;

.grouping-file-edit-container{
    display: flex;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 40px;
    -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
        padding-bottom: 20px;
    }
}

@-webkit-keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }

.grouping-file-edit-title-container{
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    cursor: pointer;
    .MuiSvgIcon-root{
        font-size: 50px !important;
    }
}

.grouping-file-edit-title{
    font-weight: 600 !important;
    font-size: 36px !important;
    color: $primary !important;
    @media only screen and (max-width: 768px) {
        font-size: 30px !important;
    }
}

.grouping-file-edit-type-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.grouping-file-edit-type-element{
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.grouping-file-edit-tags-content{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.grouping-file-edit-button-container{
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   margin-top: 30px;
   @media only screen and (max-width: 768px) {
        margin-top: 15px;
    }
}

.grouping-file-edit-button-delete{
   width: 35%;
   background-color: $whiteColor;
   color: $primary;
   border-radius: 10px;
   padding: 15px 20px;
   cursor: pointer;
   box-shadow: 0px 4px 10px -8px rgba(36,36,36,1);
   display: flex;
   justify-content: center;
   @media only screen and (max-width: 768px) {
       width: 50%;
       padding: 10px;
       background-color: $redColor;
       color: $whiteColor;
   }
   &:hover{
       background-color: $redColor;
       color: $whiteColor;
   }
}

.grouping-file-modal-text{
    font-size: 22px;
}