@use '../../../assets/styles/variables' as *;

.reports-container {
  display: flex;
  width: 100%;
  margin-top: 15px;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 40px;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.reports-title {
  font-weight: 600 !important;
  font-size: 36px !important;
  color: $primary !important;
  @media only screen and (max-width: 768px) {
    font-size: 30px !important;
  }
}

.reports-add {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    height: 50px;
    margin-bottom: 10px;
  }
}

.report-new-container {
  width: 100%;
  margin-bottom: 20px;
}

.reports-add-form {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  // @media only screen and (max-width: 768px) {
  //     width: 100%;
  //     margin-bottom: 20px;
  // }
}

.MuiAccordion-root:before {
  height: 0px !important;
}

.report-element {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.report-accordion-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.report-actionButtons-container {
  display: flex;
  padding-left: 15px;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
}

.report-accordion {
  width: 100%;
  span {
    font-size: 24px;
    font-weight: 600;
  }
}

.report-iframe-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 65px - 30px);
}

.report-icon-delete {
  cursor: pointer;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redColor;
}

.report-modal-text {
  font-size: 22px;
  text-align: center;
}

.accordions-user-list-details {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.report-user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 40px);
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
  }
}
