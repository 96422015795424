@use '../../../assets/styles/variables' as *;

.report-card-container{
    width: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    transition: all .2s ease-in-out;
    background-color: $whiteColor;
    border-radius: 25px;
    box-shadow: 0px 8px 8px -8px rgba(36,36,36,1);
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        width: 95%;
        margin-right: 0%;
    }
    .report-card{
        width: 100%;
        display: flex;
        flex-direction: column;
        color: $primary;
        height: 200px;
        position: relative;
        font-size: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        img{
            height: 100%;
            width: auto;
            border-top-left-radius: 25px;
        }
    }

    .report-card-name{
        //display: none;
        flex-direction: row;
        padding: 10px;
        font-size: 20px;
        height: 20px;
    }
}