.hmy-image-list-viewer-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
}

.hmy-image-list-viewer-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    .hmy-image-list-viewer-button-btn{
        width: 49px;
        height: 49px;
    }
}

.hmy-image-list-viewer-content{
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
}